import React, { useState } from 'react';
import { FcGoogle } from "react-icons/fc";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import Styles from './Review.module.scss';
import { appLevelConstants } from '../../constants/AppLevelConstants';
import { FaStarHalfAlt } from "react-icons/fa";
import { FaStar } from "react-icons/fa";
import { FaRegStar } from "react-icons/fa";

const Review = () => {
    const [showMore, setShowMore] = useState({});

    const handleShowMoreToggle = (id) => {
        setShowMore(prev => ({ ...prev, [id]: !prev[id] }));
    }

    const renderStars = (rating) => {
        const stars = [];
        for (let i = 1; i <= 5; i++) {
            if (i <= Math.floor(rating)) {
                stars.push(<FaStar key={i} color="#f5b301" />);
            } else if (i === Math.ceil(rating)) {
                stars.push(<FaStarHalfAlt key={i} color="#f5b301" />);
            } else {
                stars.push(<FaRegStar key={i} color="#f5b301" />);
            }
        }
        return stars;
    };

    return (
        <div className={Styles["review-container"]}>
            <h1 className={Styles["heading"]}>
                Our Google Reviews
            </h1>

            <div className={Styles["rating-section"]}>
                <div className={Styles["rating-details"]}>
                    {renderStars(4.7)} <span className={Styles["rating-span"]}>4.7 rating of 512 reviews</span>
                </div>
                <div className={Styles["write-review-button"]}>
                    <button onClick={() => window.open('https://g.page/r/CawNq-4vvQ-NEBM/review', '_blank')}>Write a Review</button>
                </div>
            </div>

            <div className={Styles["review-slider"]}>
                <Swiper
                    spaceBetween={30}
                    slidesPerView={1}
                    loop={true}
                    autoplay={{
                        delay: 3500,
                        disableOnInteraction: false,
                    }}
                    breakpoints={{
                        480: { slidesPerView: 1 },
                        768: { slidesPerView: 2 },
                        1024: { slidesPerView: 3 },
                    }}
                >
                    {appLevelConstants.googleReviews.map((review) => (
                        <SwiperSlide key={review.id}>
                            <div className={Styles["review-card"]}>
                                <img src={review.image} alt={`${review.name}'s review`} />
                                <h3>{review.name}</h3>
                                <div className={Styles["stars"]}>{renderStars(review.stars)}</div>
                                
                                <p>
                                    {showMore[review.id] ? review.description : `${review.description.substring(0, 120)}...`}
                                    <span className={Styles["show-more"]} onClick={() => handleShowMoreToggle(review.id)}>
                                        {showMore[review.id] ? ' Show Less' : ' Show More'}
                                    </span>
                                </p>
                                <div className={Styles["google-icon"]}>
                                    <FcGoogle />
                                </div>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </div>
    )
}

export default Review