import React from 'react';
import Styles from './CorporateProgram.module.scss';
import { urls } from '../../utils/urls';
import { FaCheckCircle } from "react-icons/fa";

const CorporateProgram = () => {
    const aboutList = [
        {
            id: 1,
            image: urls.corporate_1,
            title: 'Turn Challenges into Strengths',
            description: 'Our training programs are designed to boost individual and team performance, equipping women to lead with resilience and strategic insight.'
        },
        {
            id: 2,
            image: urls.corporate_2,
            title: 'Customized for Real-World Impact',
            description: 'From startups to large enterprises, our programs align with your goals, delivering maximum impact across your organization.'
        },
        {
            id: 3,
            image: urls.corporate_3,
            title: 'Empowering Women, Elevating Teams',
            description: 'Our mission is clear: Equip women with the tools to break barriers and lead boldly at every level.'
        }
    ];

    return (
        <div className={Styles["corporate-program"]}>
            {/* <img className={Styles["banner"]} src={urls.corporate_banner} alt='corporate banner' /> */}

            {/* <h1 className={Styles["headline"]}>Unleash Unstoppable Leaders - Transform Your Workforce with Iron Lady</h1>
            <h2 className={Styles["subheadline"]}>Equip your women leaders to break barriers and drive unstoppable results with Iron Lady's high-impact training</h2>

            <div className={Styles["cta-container"]}>
                <button onClick={() => window.open("https://iamironlady.com", "_blank")} className={Styles["cta"]}>Enquire Now</button>
            </div> */}

            <div className={Styles["banner-container"]}>
                <img className={Styles["banner"]} src={urls.corporate_banner} alt="corporate banner" />
                <div className={Styles["cta-container"]}>
                    <button onClick={() => window.open("https://zfrmz.in/GioEeItWzX1J4L8JYhJS", "_blank")} className={Styles["cta"]}>Enquire Now</button>
                </div>
            </div>


            <div className={Styles["about-container"]}>
                <div className={Styles["about-description-container"]}>
                    <h1 className={Styles["about-heading"]}>
                        Why Iron Lady?
                    </h1>

                    <p className={Styles["about-description"]}>
                        Break Barriers. Build Leaders. Transform Your Organization.
                    </p>
                </div>

                <div className={Styles["card-container"]}>
                    {aboutList.map((item) => (
                        <div className={Styles["card"]}>
                            <div className={Styles["card-image-container"]}>
                                <img className={Styles["card-image"]} src={item.image} alt={`${item.title} image`} />
                            </div>
                            <div className={Styles["card-content"]}>
                                <p className={Styles["card-title"]}>{item.title}</p>
                                <p className={Styles["card-description"]}>{item.description}</p>
                            </div>
                        </div>
                    ))}
                </div>

            </div>

            <div className={Styles["solution-container"]}>
                <div className={Styles["solution-description-container"]}>
                    <h1 className={Styles["solution-heading"]}>
                        Corporate Training Solutions for Every Level
                    </h1>

                    <p className={Styles["solution-description"]}>
                        Iron Lady’s programs elevate women professionals across all levels, from individual contributors to C-suite leaders. Each program is designed to develop leadership, communication, and strategic thinking to drive growth for both the individual and the organization.
                    </p>
                </div>

                <div className={Styles["solution-layout"]}>
                    <div className={Styles["solution-image"]}>
                        <img
                            src={urls.corporate_4}
                            alt="Corporate Training Solutions"
                        />
                    </div>
                    <div className={Styles["solution-content"]}>
                        <h2 className={Styles["solution-content-subheading"]}>
                            Individual Contributors
                        </h2>
                        <p className={Styles["solution-content-description"]}>
                            Essential skills to navigate and thrive in any organizational setting.
                        </p>
                        <ul className={Styles["list"]}>
                            <li><FaCheckCircle fill='red' className={Styles["check-icon"]} /> Navigating Organizational Dynamics</li>
                            <li><FaCheckCircle fill='red' className={Styles["check-icon"]} /> Time & Priority Management</li>
                            <li><FaCheckCircle fill='red' className={Styles["check-icon"]} /> Conflict Resolution and Influence</li>
                            <li><FaCheckCircle fill='red' className={Styles["check-icon"]} /> Leadership & Communication Essentials</li>
                            <li><FaCheckCircle fill='red' className={Styles["check-icon"]} /> Confidence & Visibility Building for High Performers</li>
                        </ul>
                    </div>

                </div>

                <div className={Styles["solution-layout"]}>
                    <div className={Styles["solution-content"]}>
                        <h2 className={Styles["solution-content-subheading"]}>
                            Managers
                        </h2>
                        <p className={Styles["solution-content-description"]}>
                            Leadership essentials for first-time managers and seasoned supervisors.
                        </p>
                        <ul className={Styles["list"]}>
                            <li><FaCheckCircle fill='red' className={Styles["check-icon"]} /> Transition to Leadership Bootcamp for First-Time Managers</li>
                            <li><FaCheckCircle fill='red' className={Styles["check-icon"]} /> Building & Leading Effective Teams</li>
                            <li><FaCheckCircle fill='red' className={Styles["check-icon"]} /> Strategic Decision-Making and Accountability</li>
                            <li><FaCheckCircle fill='red' className={Styles["check-icon"]} /> Navigating Workplace Politics for Managers</li>
                            <li><FaCheckCircle fill='red' className={Styles["check-icon"]} /> Strengthening Managerial Presence & Authority</li>
                        </ul>

                    </div>
                    <div className={Styles["solution-image"]}>
                        <img
                            src={urls.corporate_5}
                            alt="Corporate Training Solutions"
                        />
                    </div>


                </div>

                <div className={Styles["solution-layout"]}>
                    <div className={Styles["solution-image"]}>
                        <img
                            src={urls.corporate_6}
                            alt="Corporate Training Solutions"
                        />
                    </div>
                    <div className={Styles["solution-content"]}>
                        <h2 className={Styles["solution-content-subheading"]}>
                            Leaders
                        </h2>
                        <p className={Styles["solution-content-description"]}>
                            Advanced strategies for senior leaders to drive organizational impact.
                        </p>
                        <ul className={Styles["list"]}>
                            <li><FaCheckCircle fill='red' className={Styles["check-icon"]} /> Advanced Leadership Development Programs</li>
                            <li><FaCheckCircle fill='red' className={Styles["check-icon"]} /> Women in Leadership 360 Impact Assessment</li>
                            <li><FaCheckCircle fill='red' className={Styles["check-icon"]} /> Leading Cultural Change & High-Performance Teams</li>
                            <li><FaCheckCircle fill='red' className={Styles["check-icon"]} /> Emotional Intelligence for Senior Leaders</li>
                            <li><FaCheckCircle fill='red' className={Styles["check-icon"]} /> Building Strategic Influence & Negotiation Skills</li>
                            <li><FaCheckCircle fill='red' className={Styles["check-icon"]} /> C-Suite Readiness Workshop</li>
                        </ul>

                    </div>

                </div>

                <div className={Styles["common-cta-container"]}>
                    <button onClick={() => window.open("https://zfrmz.in/GioEeItWzX1J4L8JYhJS", "_blank")} className={Styles["common-cta"]}>
                        Explore How We Can Elevate Your Team
                    </button>
                </div>
            </div>

            <div className={Styles["trust-container"]}>
                <div className={Styles["trust-description-container"]}>
                    <h1 className={Styles["trust-heading"]}>
                        Corporate Training Solutions for Every Level
                    </h1>
                    <p className={Styles["trust-description"]}>
                        Iron Lady’s programs elevate women professionals across all levels, from individual contributors to C-suite leaders. Each program is designed to develop leadership, communication, and strategic thinking to drive growth for both the individual and the organization.
                    </p>
                </div>

                <div className={Styles["trust-numbers-container"]}>
                    <h2 className={Styles["trust-subheading"]}>
                        Why Trust Iron Lady?
                    </h2>
                    <div className={Styles["numbers-grid"]}>
                        <div className={Styles["number-item"]}>
                            <span className={Styles["number"]}>10+</span><br />
                            <span className={Styles["description"]}>Years of Empowering Women Leaders</span>
                        </div>
                        <div className={Styles["number-item"]}>
                            <span className={Styles["number"]}>300+</span><br />
                            <span className={Styles["description"]}>Corporates Transformed</span>
                        </div>
                        <div className={Styles["number-item"]}>
                            <span className={Styles["number"]}>20+</span><br />
                            <span className={Styles["description"]}>Industries Served</span>
                        </div>
                        <div className={Styles["number-item"]}>
                            <span className={Styles["number"]}>1500+</span><br />
                            <span className={Styles["description"]}>High-Impact Training Sessions</span>
                        </div>
                        <div className={Styles["number-item"]}>
                            <span className={Styles["number"]}>78000+</span><br />
                            <span className={Styles["description"]}>Women Professionals Trained</span>
                        </div>
                    </div>
                </div>
            </div>

            <div className={Styles["benefit-container"]}>
                <div className={Styles["heading-container"]}>
                    <h1 className={Styles["benefit-heading"]}>
                        Empower Fierce Leaders to Drive Lasting Impact
                    </h1>
                </div>

                <div className={Styles["cards-container"]}>
                    <div className={Styles["card"]}>
                        <h2 className={Styles["card-title"]}>Organization-Wide Benefits</h2>
                        <ul className={Styles["card-list"]}>
                            <li>
                                <FaCheckCircle className={Styles["checkbox-icon"]} />
                                Consistent, impactful leadership training at all levels
                            </li>
                            <li>
                                <FaCheckCircle className={Styles["checkbox-icon"]} />
                                Alignment with Iron Lady’s Business War Tactics and global standards
                            </li>
                            <li>
                                <FaCheckCircle className={Styles["checkbox-icon"]} />
                                Leadership development integrated with business goals
                            </li>
                            <li>
                                <FaCheckCircle className={Styles["checkbox-icon"]} />
                                Fosters a culture of growth and continuous learning
                            </li>
                        </ul>
                    </div>
                    <div className={Styles["card"]}>
                        <h2 className={Styles["card-title"]}>Team Benefits</h2>
                        <ul className={Styles["card-list"]}>
                            <li>
                                <FaCheckCircle className={Styles["checkbox-icon"]} />
                                Customized solutions aligned with team goals
                            </li>
                            <li>
                                <FaCheckCircle className={Styles["checkbox-icon"]} />
                                Engaging, strategic leadership sessions for measurable impact
                            </li>
                            <li>
                                <FaCheckCircle className={Styles["checkbox-icon"]} />
                                High-impact training developed with team leaders
                            </li>
                        </ul>
                    </div>
                    <div className={Styles["card"]}>
                        <h2 className={Styles["card-title"]}>Participant Benefits</h2>
                        <ul className={Styles["card-list"]}>
                            <li>
                                <FaCheckCircle className={Styles["checkbox-icon"]} />
                                Practical tactics and experiential learning to build leadership presence
                            </li>
                            <li>
                                <FaCheckCircle className={Styles["checkbox-icon"]} />
                                Mentorship from industry experts
                            </li>
                            <li>
                                <FaCheckCircle className={Styles["checkbox-icon"]} />
                                Growth supported by Iron Lady’s 70:20:10 development model
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div className={Styles["exp-container"]}>
                <div className={Styles["exp-content"]}>
                    <div className={Styles["exp-image"]}>
                        <img src={urls.corporate_7} alt="Virtual Learning" />
                    </div>
                    <div className={Styles["exp-text"]}>
                        <h1 className={Styles["exp-heading"]}>
                            Virtual Interactive Learning Experience
                        </h1>
                        <p className={Styles["exp-description"]}>
                            Our virtual learning platform is interactive, engaging, and customized to meet your team's unique needs—no matter where they are. Partner with Iron Lady to empower women leaders across your organization with a dynamic virtual experience.
                        </p>
                    </div>
                </div>
            </div>

            <div className={Styles["enquiry-container"]}>
                <h2 className={Styles["enquiry-text"]}>
                    Ready to take your team’s leadership journey to the next level? Connect with us to discover Iron Lady's transformative corporate training programs.
                </h2>
                <div className={Styles["common-cta-container"]}>
                    <button onClick={() => window.open("https://zfrmz.in/GioEeItWzX1J4L8JYhJS", "_blank")} className={Styles["common-cta"]}>Book a Consultation</button>
                </div>
            </div>


        </div>
    )
}

export default CorporateProgram