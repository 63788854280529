import "./App.scss";
import Home from "./pages/Home";
import Program from "./pages/Programs";
import { Routes, Route } from "react-router-dom";
import Header from "./components/common_components/header/Header";
import Footer from "./components/common_components/footer/Footer";
import { useLocation } from "react-router-dom";
import Impact from "./pages/Impact";
import IndividualPrograms from "./pages/IndividualPrograms";
import About from "./pages/about/About";
import Resources from "./pages/resources/Resources.js";
import { useEffect } from "react";
import UserIndividualPage from "../src/components/user-individual-page/UserIndividualPage";
import { useMemo } from "react";
import { appLevelConstants } from "./constants/AppLevelConstants";
import AppChat from "./pages/AppChat";
import LatestHappening from "./pages/LatestHappening";
import ContactUs from "./components/contact-us/ContactUs";
import LepPolicy from "./pages/policy/Policy";
import CouponValidation from "./components/common_components/forms/coupon-validation/CouponValidation";
import { Provider } from "react-redux";
import store from "./redux/store";
import ThankYouPopUp from "./pages/thank-you-pop-up/ThankYouPopUp";
import LatestHappeningDetailPage from "../src/components/latest-happening-detail-page/LatestHappeningDetailPage";
import WisdomInsightDetailPage from "./components/wisdom-insight-detail-page/WisdomInsightDetailPage";
import JobPosts from "../src/components/job-posts/JobPosts";
import JobPostsDetailPage from "../src/components/job-posts-detail-page/JobPostsDetailPage";
import CompanyView from "./components/company-view/CompanyView";
import GroupChats from "./components/group-chats/GroupChats";
import BarCharts from "./components/bar-charts/BarCharts";
import Dashboards from "./components/dashboards/Dashboards";
import AdminDashboards from "./components/admin-dashboard/AdminDashboard";
import PayButtons from "./pages/pay-buttons/PayButtons";
import FeedsDescription from "./components/feeds-description/FeedsDescription.js";
import Newsletter from "./components/newsletter/newsletter.js";
import NewsletterDetails from "./components/newsletter-details/NewsletterDetails.js";
import Champions from "./components/champions/Champions.js";
import TestPDF from "./components/test-pdf/TestPDF.js";
import BlogsContainer from "./components/blogs-container/BlogsContainer.js";
import Blog from "./components/blog/blog.js";
import Article from "./components/article/article.js";
import usePageTracking from "./utils/analytics.js";
import Scholarship from "./components/scholarship/Scholarship.js";
import Partnership from "./components/partnership/Partnership.js";
import CorporateProgram from "./components/corporate-program/CorporateProgram.js";
import RajeshBhat from "./components/rajesh-bhat/RajeshBhat.js";
import VisionMission from "./components/vision-mission/VisionMission.js";
import Founders from "./components/founders/Founders.js";
import AboutCommunity from "./components/about-community/AboutCommunity.js";
import Publication from "./components/publication/Publication.js";
import VideoResources from "./components/video-resources/VideoResources.js";
import Manifesto from "./components/manifesto/Manifesto.js";
import FAQ from "./components/faq/FAQ.js";
import Events from "./components/events/Events.js";

export default function App() {
  const location = useLocation();
  const currentPath = location.pathname;

  usePageTracking();

  useEffect(() => window.scrollTo(0, 0), [location]);

  const hideHeaderFooter = useMemo(
    () =>
      location.pathname.includes("programRegistration") ||
      location.pathname.includes("dashboards") ||
      location.pathname.includes("/thankYou") ||
      location.pathname.includes("/group-chat") ||
      location.pathname.includes("/pay_method") ||
      location.pathname.includes("/appChat"),
    [location]
  );

  useEffect(() => {
    const paths = [
      "//",
      "programs",
      "aboutUs",
      "impact",
      "success-story",
      "individualPrograms",
      // "champions"
    ];

    const advertisments = appLevelConstants.advertisments;
    if (
      paths.includes(currentPath.split("/")[1]) &&
      advertisments.includes(currentPath.split("/")[2])
    ) {
      localStorage.setItem("leadSourceName", currentPath.split("/")[2]);
    } else if (paths.includes(currentPath.split("/")[1])) {
      localStorage.setItem("leadSourceName", "Iron Lady Website");
    } else if (!advertisments.includes(currentPath.split("/")[1])) {
      localStorage.setItem("leadSourceName", "Iron Lady Website");
    } else {
      const match = currentPath.match(/\/(.+)/);
      const extractedValue = match && match[1];
      localStorage.setItem("leadSourceName", extractedValue);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // useEffect(() => {
  //   initGA('G-0YX0YS136B'); 
  // }, []);

  return (
    <>
      <Provider store={store}>
        {!hideHeaderFooter && <Header />}
        {/*<RouteTracker /> */}
        <Routes>
          <Route
            exact
            path={
              currentPath === "/google"
                ? "google"
                : currentPath === "/facebook"
                  ? "facebook"
                  : currentPath === "/linkedin"
                    ? "linkedin"
                    : "/"
            }
            element={<Home />}
          ></Route>
          <Route exact path="/programs" element={<Program />}></Route>
          {/* <Route exact path="/community" element={<Community />}></Route> */}
          <Route exact path="/aboutUs" element={<About />}></Route>
          <Route path="/aboutUs/rajesh-bhat" element={<RajeshBhat />} />
          <Route path="/aboutUs/purpose-and-vision" element={<VisionMission />} />
          <Route path="/aboutUs/manifesto" element={<Manifesto />} />
          <Route path="/aboutUs/founders" element={<Founders />} />
          <Route path="/aboutUs/community" element={<AboutCommunity />} />
          <Route path="/resources/publication" element={<Publication />} />
          <Route exact path="/resources" element={<Resources />}></Route>
          <Route exact path="/resources/video-resources" element={<VideoResources />}></Route>
          <Route exact path="resources/faq" element={<FAQ />}></Route>
          <Route exact path="/events" element={<Events />}></Route>
          <Route exact path="/impact" element={<Impact />}></Route>
          <Route exact path="/appChat" element={<AppChat />}></Route>
          <Route exact path="/latest" element={<LatestHappening />}></Route>
          <Route exact path="/contactUs" element={<ContactUs />}></Route>
          <Route exact path="/newsletter" element={<Newsletter />}></Route>
          <Route path="/newsletter/:year/:month" element={<NewsletterDetails />}></Route>
          <Route exact path="/champions" element={<Champions />}></Route>
          <Route exact path="/scholarship" element={<Scholarship />}></Route>
          <Route exact path="/partnership" element={<Partnership />}></Route>
          <Route exact path="/corporateprogram" element={<CorporateProgram />}></Route>
          <Route exact path="/testpdf" element={<TestPDF />}></Route>
          <Route exact path="/blogs" element={<BlogsContainer />}></Route>
          <Route path="/blogs/:category/:subcategory/:title" element={<Blog />}></Route>
          <Route path="/articles/:title" element={<Article />}></Route>
          <Route
            exact
            path="/thankYou/:programName/:formType"
            element={<ThankYouPopUp />}
          ></Route>
          <Route
            exact
            path="/programRegistration/:programData"
            element={<CouponValidation />}
          ></Route>
          <Route
            exact
            path="/individualPrograms/:programName"
            element={<IndividualPrograms />}
          ></Route>
          <Route
            exact
            path="/individualPrograms/:language/:programName"
            element={<IndividualPrograms />}
          />
          <Route
            exact
            path="/individualPrograms/:programName/:letsConnect"
            element={<IndividualPrograms />}
          ></Route>
          <Route
            exact
            path="/individualPrograms/:source/:programName"
            element={<IndividualPrograms />}
          ></Route>

          <Route
            exact
            path="/success-story/:name/:id"
            element={<UserIndividualPage />}
          ></Route>
          <Route exact path="*" element={<Home />}></Route>
          <Route
            exact
            path="/individualPrograms/:programName/policy"
            element={<LepPolicy />}
          ></Route>
          <Route
            exact
            path="/latestHappeningDetailPage/:name/:id"
            element={<LatestHappeningDetailPage />}
          ></Route>
          <Route
            exact
            path="/wisdomInsightDetailPage/:id"
            element={<WisdomInsightDetailPage />}
          ></Route>
          <Route
            exact
            path="/feedsDescription/:id"
            element={<FeedsDescription />}
          ></Route>
          <Route exact path="/jobPosts" element={<JobPosts />}></Route>
          <Route
            exact
            path="/jobPostsDetailPage/:id"
            element={<JobPostsDetailPage />}
          ></Route>
          <Route
            exact
            path="/companyView/:id"
            element={<CompanyView />}
          ></Route>
          <Route exact path="/group-chat" element={<GroupChats />}></Route>
          <Route exact path="/bar-chart" element={<BarCharts />}></Route>
          <Route
            exact
            path="/dashboards/:BatchId/:UserId"
            element={<Dashboards />}
          ></Route>
          <Route
            exact
            path="/admin-dashboards"
            element={<AdminDashboards />}
          ></Route>
          <Route
            exact
            path="/pay_method_confirmation"
            element={<PayButtons />}
          ></Route>
        </Routes>
        {!hideHeaderFooter && <Footer />}
      </Provider>
    </>
  );
}


