import React from "react";
import Styles from "./Events.module.scss";

const events = [
  // {
  //   id: 1,
  //   image: "https://cms.iamironlady.com/wp-content/uploads/2025/02/Winning-ways-for-women-scaled.jpg",
  //   name: "Winning Ways For Women",
  //   date: "April 26, 2025",
  //   status: "Upcoming",
  //   description: "An Exclusive Event to Celebrate Women’s Success and Leadership.",
  //   link: "https://events.iamironlady.com/winningwaysforwomen2025",
  // },
  {
    id: 2,
    image: "https://cms.iamironlady.com/wp-content/uploads/2025/02/CR-Club.png",
    name: "Iron Lady 1CR+ Club",
    date: "March 10, 2024",
    status: "Ended",
    description: "Iron Lady celebrated Women's Day on March 10th, 2024, at Hyatt Bangalore, honoring over 50 women who achieved Crore+ yearly incomes through the platform. Led by Rajesh Bhat and CEO Suvarna Hegde, the event highlighted Iron Lady's mission to empower women and foster a global community of over 78,000 members.",
    link: "https://crclub.iamironlady.com/",
  },
  {
    id: 3,
    image: "https://cms.iamironlady.com/wp-content/uploads/2024/05/Event_2.jpg",
    name: "Walk To The Board - Walkathon",
    date: "November 5, 2023",
    status: "Ended",
    description: "Walk to the Board Walkathon is an empowering event dedicated to all Inspiring Women to fast-track their journey towards Senior Leadership Roles in their Industries. It aims to celebrate the success stories of women leaders and encourage participants to join the 100 Board Members Initiative to achieve Leadership Excellence.",
    link: "https://event.iamironlady.com",
  },
];

const Events = () => {
  return (
    <div className={Styles["events-container"]}>
      <h2 className={Styles["events-title"]}>Events</h2>
      <div className={Styles["events-list"]}>
        {events.map((event) => (
          <div key={event.id} className={Styles["event-card"]}>
            <div className={Styles["event-image"]}>
              <img src={event.image} alt={event.name} />
            </div>
            <div className={Styles["event-details"]}>
              <h3>{event.name}</h3>
              <p className={Styles["event-date"]}>{event.date}</p>
              <p className={`${Styles["event-status"]} ${Styles[event.status.toLowerCase()]}`}>
                {event.status}
              </p>
              <p className={Styles["event-description"]}>{event.description}</p>
              <a href={event.link} className={Styles["event-button"]}>
                View Details
              </a>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Events;