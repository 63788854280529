const backendBaseUrl = 'https://api.iamironlady.com/api';
// const backendBaseUrl = 'http://localhost:3001/api';
const s3BaseUrl = 'https://d1ixtxt2uzttul.cloudfront.net';
export const urls = {
    eventRegistrationLink : 'https://events.iamironlady.com/Walkathon#/buyTickets?lang=en',
    getMenuList : `${backendBaseUrl}/getprogrampagedetails`,
    updateUser : "https://api.iamironlady.com/api/update-user-details-from-app",
    getBatchDetails : `${backendBaseUrl}/batch-details`,
    resendOtp : `${backendBaseUrl}/resend-otp`,
    otpVerification : `${backendBaseUrl}/verify-otp`,
    userValidation : `${backendBaseUrl}/verify-user`,
    fetchIndividualProgramsDataApi : `${backendBaseUrl}/getprogrampagedetails`,
    individualProgramApi : `${backendBaseUrl}/website-app-config`,
    programEnroll :`${backendBaseUrl}/getpaymentlink`,
    validateCoupon: `${backendBaseUrl}/validateDiscountVoucher`,
    registerPogram: `${backendBaseUrl}/generateencryptpaymentlink`,
    decryptProgramResponse: `${backendBaseUrl}/generatedecryptpaymentlink`,
    getInTouch : `${backendBaseUrl}/getInTouch`,
    selectBatch : `${backendBaseUrl}/batchSelection`,
    refer : `${backendBaseUrl}/refer`,
    addMessage : `${backendBaseUrl}/add-message`,
    uploadImgaePdf : `${backendBaseUrl}/upload-files`,
    communityChat : "https://api.iamironlady.com/communityChat",
    getAllCommunity : `${backendBaseUrl}/get-all-community-group`,
    getAllCommunityMessages : `${backendBaseUrl}/get-all-community-message`,
    getCommunityUsers : `${backendBaseUrl}/get-all-group-user-details`,
    appStore : "https://play.google.com/store/apps/details?id=com.iamironlady.courses",
    ilTwitter : "https://twitter.com/IamIronLadyIL",
    ilFacebook : "https://www.facebook.com/IamIronLady/",
    ilLinkedon :"https://www.linkedin.com/company/iron-lady-1/",
    ilInstagram : "https://www.instagram.com/iamironladyil/",
    ilYoutube : "https://www.youtube.com/@iamironlady",
    impactVideo : `${s3BaseUrl}/videos/impact_video.mp4`,
    aboutUsVideo : `${s3BaseUrl}/videos/rajesh_bhatt_tedx.mp4`,
    impactThumbneil : `${s3BaseUrl}/images/thumbnail_Impact page.png`,
    aboutUsThumbneil : `${s3BaseUrl}/images/About us - tedx.png`,
    deleteIcon : `${s3BaseUrl}/images/deleteIcon.png`,
    noData : `${s3BaseUrl}/images/noData.png`,
    blockIcon : `${s3BaseUrl}/images/block.png`,
    stepperIcon : `${s3BaseUrl}/images/stepper.png`,
    filterIcon : `${s3BaseUrl}/images/filter.png`,
    nextArrow : `${s3BaseUrl}/images/next-arrow.png`,
    prevArrow : `${s3BaseUrl}/images/prev-arrow.png`,
    upArrow : `${s3BaseUrl}/images/up-arrow.svg`,
    ironLadyLanding : `${s3BaseUrl}/images/ILLadyNew-compressed.webp`,
    defaultGroupIcon: `${s3BaseUrl}/images/default-group-icon.png`,
    downloadFile: `${s3BaseUrl}/images/downLoadFile.png`,
    dots: `${s3BaseUrl}/images/dots.png`,
    document: `${s3BaseUrl}/images/doc.png`,
    whiteLogo: `${s3BaseUrl}/images/whiteLogo.png`,
    blackLogo: `${s3BaseUrl}/images/logo-black-bg.png`,
    logoGif: `${s3BaseUrl}/images/logo.gif`,
    hardHit1: `${s3BaseUrl}/images/hardHit1.svg`,
    hardHit2: `${s3BaseUrl}/images/hardHit2.svg`,
    trustedWomen1: `${s3BaseUrl}/images/trustedWomen1.svg`,
    trustedWomen2: `${s3BaseUrl}/images/trustedWomen2.svg`,
    trustedWomen3: `${s3BaseUrl}/images/trustedWomen3.svg`,
    individualProgram1: `${s3BaseUrl}/images/individualProgram1.svg`,
    individualProgram2: `${s3BaseUrl}/images/individualProgram2.svg`,
    individualProgram3: `${s3BaseUrl}/images/individualProgram3.svg`,
    image1: `${s3BaseUrl}/images/image1.png`,
    image2: `${s3BaseUrl}/images/image2.png`,
    image3: `${s3BaseUrl}/images/image3.png`,
    image4: `${s3BaseUrl}/images/image4.png`,
    image5: `${s3BaseUrl}/images/image5.png`,
    championBanner: `${s3BaseUrl}/images/champion_banner.png`,
    referralImg1: `${s3BaseUrl}/images/referral_1.png`,
    referralImg2: `${s3BaseUrl}/images/referral_2.png`,
    referralImg3: `${s3BaseUrl}/images/referral_3.png`,
    referralImg4: `${s3BaseUrl}/images/referral_4.jpg`,
    referralImg5: `${s3BaseUrl}/images/referral_5.jpg`,
    referralImg6: `${s3BaseUrl}/images/referral_6.jpg`,
    referralImg7: `${s3BaseUrl}/images/referral_7.jpg`,
    referralImg8: `${s3BaseUrl}/images/referral_8.jpg`,
    scholarshipImg1: `${s3BaseUrl}/images/Scholarship_1.jpg`,
    scholarshipImg2: `${s3BaseUrl}/images/Scholarship_2.jpg`,
    scholarshipImg3: `${s3BaseUrl}/images/Scholarship_3.jpg`,
    groupImage: `${s3BaseUrl}/images/partners.png`,
    rightUpArrow: `${s3BaseUrl}/images/rightArrow.svg`,
    rightArrow :  `${s3BaseUrl}/images/right-arrow.png`,
    closeIcon :  `${s3BaseUrl}/images/close.png`,
    attachFileIcon :  `${s3BaseUrl}/images/attach-file.png`,
    backIcon :  `${s3BaseUrl}/images/back.png`,
    redTickIcon :  `${s3BaseUrl}/images/tick.png`,
    userIcon :  `${s3BaseUrl}/images/user.png`,
    rupeeIcon :  `${s3BaseUrl}/images/rupee.png`,
    experienceIcon :  `${s3BaseUrl}/images/experience.png`,
    locationIcon :  `${s3BaseUrl}/images/location.png`,
    linkedinIcon :  `${s3BaseUrl}/images/linkedin.png`,    
    careerBanner :  `${s3BaseUrl}/images/Career_Banner.webp`,
    chitra : `${s3BaseUrl}/curators/chitra.jpeg`,
    rajesh : `${s3BaseUrl}/curators/Rajesh.png`,
    simon : `${s3BaseUrl}/curators/simon.jpeg`,
    sridhar : `${s3BaseUrl}/curators/sridhar.jpeg`,
    suverna : `${s3BaseUrl}/curators/suvrna.png`,
    minalBhagat : `${s3BaseUrl}/testimonials/Minal Bhagat-compressed.jpg`,
    pushpa : `${s3BaseUrl}/testimonials/Pushpa-compressed.jpg`,
    vinathHegde : `${s3BaseUrl}/testimonials/vinath hegde-compressed.jpg`,
    poornima : `${s3BaseUrl}/testimonials/Poornima Dixit.png`,
    anand : `${s3BaseUrl}/testimonials/Anand Sudarshan.png`,
    harvinder : `${s3BaseUrl}/testimonials/Harvinder Mona.png`,
    nandita : `${s3BaseUrl}/images/google_reviews/nandita.png`,
    surya : `${s3BaseUrl}/images/google_reviews/surya.png`,
    veda : `${s3BaseUrl}/images/google_reviews/veda.png`,
    darshana : `${s3BaseUrl}/images/google_reviews/darshana.png`,
    archana : `${s3BaseUrl}/images/google_reviews/archana.png`,
    sunila : `${s3BaseUrl}/images/google_reviews/sunila.png`,
    sreeveni : `${s3BaseUrl}/images/google_reviews/sreeveni.png`,
    chaya : `${s3BaseUrl}/images/google_reviews/chaya.png`,
    rabeka : `${s3BaseUrl}/images/google_reviews/rabeka.png`,
    karuna : `${s3BaseUrl}/images/google_reviews/karuna.png`,
    partnership_banner : `${s3BaseUrl}/images/website/partnership_page_banner.jpg`,
    partnership_model : `${s3BaseUrl}/images/website/partnership_page_model.jpg`,
    corporate_banner : `${s3BaseUrl}/images/corporate_banner.png`,
    corporate_1: `${s3BaseUrl}/images/corporate_img1.jpg`,
    corporate_2: `${s3BaseUrl}/images/corporate_img2.jpg`,
    corporate_3: `${s3BaseUrl}/images/corporate_img3.jpg`,
    corporate_4: `${s3BaseUrl}/images/corporate_4.jpeg`,
    corporate_5: `${s3BaseUrl}/images/corporate_5.jpeg`,
    corporate_6: `${s3BaseUrl}/images/corporate_6.jpeg`,
    corporate_7: `${s3BaseUrl}/images/corporate_7.jpeg`,
    hero_banner_1: `${s3BaseUrl}/images/Newbanner/input/Event poster 26 april updated 1600x559 (1) (1).jpg`,
    hero_banner_2: `${s3BaseUrl}/images/hero_banner_5.jpg`,
    hero_banner_3: `${s3BaseUrl}/images/hero_banner_2.jpg`,
    hero_banner_4: `${s3BaseUrl}/images/hero_banner_6.jpg`,
    hero_banner_5: `${s3BaseUrl}/images/hero_banner_4.jpg`,
    hero_banner_6: `${s3BaseUrl}/images/hero_banner_9.jpg`,
    
    headHeldHighLogo: `${s3BaseUrl}/logos/head_held_high_foundation.jpg`,
    oneBridgeLogo: `${s3BaseUrl}/logos/1_bridge.jpg`,
    ironLadyLogo: `${s3BaseUrl}/logos/iron_lady.jpg`,
    winnerBenchLogo: `${s3BaseUrl}/logos/winnerbench.jpg`,
}
