import React from 'react';
import Styles from './VisionMission.module.scss';
import { FaCheckCircle, FaRocket } from "react-icons/fa";

const VisionMission = () => {
  return (
    <section className={Styles["purpose-vision-section"]}>
      <section className={Styles["purpose-section"]}>
    <h1>Our Purpose</h1>
    <div className={Styles.content}>
      <div className={Styles["purpose-item"]}>
        <FaRocket className={Styles["purpose-icon"]} />
        <p>Our Purpose is to enable a million women to reach the TOP.</p>
      </div>
    </div>
  </section>

      <section className={Styles["vision-section"]}>
        <h1>Our Vision</h1>
        <div className={Styles.content}>
          <div className={Styles["vision-item"]}>
            <FaCheckCircle className={Styles["check-icon"]} />
            <p>Our Vision is to see a world of real equality where each person gets an opportunity to excel!</p>
          </div>
          <div className={Styles["vision-item"]}>
            <FaCheckCircle className={Styles["check-icon"]} />
            <p>We work collaboratively, with an obsession to make a profound impact on individuals and each other.</p>
          </div>
          <div className={Styles["vision-item"]}>
            <FaCheckCircle className={Styles["check-icon"]} />
            <p>We honour authenticity, ownership, and rigor, making a difference with excellence towards impact.</p>
          </div>
          <div className={Styles["vision-item"]}>
            <FaCheckCircle className={Styles["check-icon"]} />
            <p>Every customer deeply impacted is as important as the profit we make.</p>
          </div>
          <div className={Styles["vision-item"]}>
            <FaCheckCircle className={Styles["check-icon"]} />
            <p>We actively seek partners, talent, and collaboration towards our mission, keeping an open mind to working with others.</p>
          </div>
          <div className={Styles["vision-item"]}>
            <FaCheckCircle className={Styles["check-icon"]} />
            <p>Together, let’s shape a world where Women Leaders not only succeed but thrive, rewriting the narrative for a new generation of leaders.</p>
          </div>
        </div>
      </section>
    </section>
  );
};

export default VisionMission;