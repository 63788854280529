// import React from 'react';
// import Styles from './Manifesto.module.scss';

// const Manifesto = () => {
//     return (
//         <section className={Styles["manifesto-section"]}>
//             <h1>Our Manifesto</h1>
//             <div className={Styles["content"]}>
//                 <p>
//                     In a world once dominated by physical energy, today's landscape is shaped by logic and data.
//                 </p>
//                 <p>
//                     Yet, we recognise the need for a different force – the power of empathy, emotion, and energy.
//                 </p>
//                 <p>
//                     Women Leaders play a pivotal role, steering the world forward through creativity, intuitive decision-making, and empathetic leadership. That's why they are not just necessary; they're in demand.
//                 </p>
//                 <p>
//                     Women possess these strengths naturally, but traditional systems often overlook them.
//                 </p>
//                 <p>
//                     <b>The reality?</b> Women face <b>societal stereotypes and conditioning</b>. They battle <b>limited support systems </b> for working mothers and the constant struggle against <b>limiting beliefs</b>.
//                 </p>
//                 <p>
//                     <b>Iron Lady exists because we see this</b>. Our unwavering focus is to support women from all backgrounds in overcoming challenges and crafting a fulfilling life, both personally and professionally.
//                 </p>
//                 <p>
//                 We strive every day to support, educate, and bridge the gap between the ideal and the actual. Through our programs, guidance, and supportive communities, we enable women to break through barriers, fast-track their careers, and maximise their potential.
//                 </p>
//             </div>
//         </section>
//     )
// }

// export default Manifesto

import React from 'react';
import Styles from './Manifesto.module.scss';
import { GiBrain, GiLightBulb, GiStrong, GiRosaShield, GiStairsGoal, GiBoltShield } from 'react-icons/gi';

const Manifesto = () => {
    return (
        <section className={Styles["manifesto-section"]}>
            <h1>Our Manifesto</h1>
            <div className={Styles["content"]}>
                <div className={Styles["grid"]}>
                    <div className={Styles["grid-item"]}>
                        <GiBrain className={Styles["icon"]} />
                        <p>In a world once dominated by physical energy, today's landscape is shaped by logic and data.</p>
                    </div>
                    <div className={Styles["grid-item"]}>
                        <GiBoltShield className={Styles["icon"]} />
                        <p>Yet, we recognise the need for a different force – the power of empathy, emotion, and energy.</p>
                    </div>
                    <div className={Styles["grid-item"]}>
                        <GiLightBulb className={Styles["icon"]} />
                        <p>Women Leaders play a pivotal role, steering the world forward through creativity, intuitive decision-making, and empathetic leadership.</p>
                    </div>
                    <div className={Styles["grid-item"]}>
                        <GiStrong className={Styles["icon"]} />
                        <p>Women possess these strengths naturally, but traditional systems often overlook them.</p>
                    </div>
                    <div className={Styles["grid-item"]}>
                        <GiRosaShield className={Styles["icon"]} />
                        <p><b>The reality?</b> Women face <b>societal stereotypes and conditioning</b>. They battle <b>limited support systems</b> for working mothers and the constant struggle against <b>limiting beliefs</b>.</p>
                    </div>
                    <div className={Styles["grid-item"]}>
                        <GiStairsGoal className={Styles["icon"]} />
                        <p><b>Iron Lady exists because we see this</b>. Our unwavering focus is to support women from all backgrounds in overcoming challenges and crafting a fulfilling life, both personally and professionally.</p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Manifesto;