import React, { useState } from "react";
import Styles from "./FAQ.module.scss";
import { Link } from "react-router-dom";

const FAQ = () => {
  const [activeSection, setActiveSection] = useState("brand");

  const faqData = {
    brand: [
      {
        question: "What is Iron Lady?",
        answer: (
          <>
            Iron Lady is India’s leading leadership platform for women, helping them advance into top leadership roles through high-impact programs.
            Beyond training, we have built a strong community of ambitious women across India, supporting each other’s growth and success.
            To know more, check out our {" "}
            <Link to="/aboutUs" style={{ color: "blue", textDecoration: "underline" }}>
              About Us
            </Link>{" "}
            page and become a part of the {" "}
            <Link to="/latest" style={{ color: "blue", textDecoration: "underline" }}>
              Iron Lady Community.
            </Link>
          </>
        ),
      },
      {
        question: "Who founded Iron Lady?",
        answer: (
          <>
            Iron Lady was founded by global leaders committed to women’s leadership.
            <br />
            Meet our Founder & Director – Rajesh Bhat: The visionary entrepreneur behind Head Held High, 1Bridge, Iron Lady, Winner Bench, and C-Suite League.
            <br />
            Our Founder & CEO – Suvarna Hegde: Before Iron Lady, she led major technology projects at Infosys, Robert Bosch, and Philips. She's an expert in Business War Tactics for Women.
            <br />
            Meet Sridhar Sambandam – Co-founder & Director: He revived billion-dollar companies like Bajaj Auto and Escorts as their CEO/President.
            <br />
            Simon Newman – Co-founder & Chairman: A seasoned global CEO who led Aviva Singapore and held leadership roles at Barclays, Prudential, and Hong Leong Bank.
            <br />
            Chitra Talwar – Investor, Board Member & Advisor: With 30+ years in the FMCG sector, she led sales, marketing, and operations, retiring as VP, International Sales at PepsiCo.
            <br />
            Know more about our founders here - {" "}
            <Link to="/aboutUs/founders" style={{ color: "blue", textDecoration: "underline" }}>
              Our Founders
            </Link>.
          </>
        ),
      },
      {
        question: "Where is Iron Lady located?",
        answer: (
          <>
            Our corporate head office is in ITPL, Bangalore. However, we serve women worldwide through our online programs and also conduct events and offline programs.
            To know more or get in touch, visit our {" "}
            <Link to="/contactUs" style={{ color: "blue", textDecoration: "underline" }}>
              Contact Us
            </Link>{" "}
            page.
          </>
        ),
      },
      {
        question: "What values does Iron Lady uphold?",
        answer:
          "Every day, we wake up with one mission—to help a million women rise to the top. It’s not just about climbing the career ladder; it’s about thriving in every aspect of life. We stand by the women we support, championing their growth, pushing boundaries, and keeping our word with unwavering commitment and integrity.",
      },
      {
        question: "How can I contact Iron Lady?",
        answer: (
          <>
            You can reach us via email or phone. Find all the details on our {" "}
            <Link to="/contactUs" style={{ color: "blue", textDecoration: "underline" }}>
              Contact Us
            </Link>{" "}
            page.
          </>
        ),
      },
      {
        question: "What impact has Iron Lady created so far?",
        answer: (
          <>
            Over 78,000+ women have gone through our programs, with 100+ women achieving ₹1 CR+ salary income! Many have stepped into C-suite roles, joined boards, and led game-changing business transformations.
            To know more, check out our {" "}
            <Link to="/impact" style={{ color: "blue", textDecoration: "underline" }}>
              Impact Page
            </Link>.
          </>
        ),
      },
      {
        question: "Does Iron Lady have a community?",
        answer: (
          <>
            Yes! Iron Lady has a strong community of women leaders who support, mentor, and uplift each other. It’s a space where women connect, share experiences, and grow together, helping each other reach the top.
            To know more, check out our {" "}
            <Link to="/latest" style={{ color: "blue", textDecoration: "underline" }}>
              Community Page
            </Link>.
          </>
        ),
      },
      {
        question: "Does Iron Lady offer partnership opportunities?",
        answer: (
          <>
            Yes, Iron Lady offers partnership opportunities. We collaborate with organizations that share our mission to empower women leaders.
            To explore partnership possibilities, please visit our {" "}
            <Link to="/partnership" style={{ color: "blue", textDecoration: "underline" }}>
              Partnership Page
            </Link>.
          </>
        ),
      },
      {
        question: "How long has Iron Lady been operating?",
        answer: (
          <>
            Iron Lady has been empowering women since its founding in 2011. To learn more about our journey and impact, visit our {" "}
            <Link to="/aboutUs" style={{ color: "blue", textDecoration: "underline" }}>
              About Us Page
            </Link>.
          </>
        ),
      },
      {
        question: "How can my organization become a partner?",
        answer: (
          <>
            Interested in partnering with us? Visit the {" "}
            <Link to="/partnership" style={{ color: "blue", textDecoration: "underline" }}>
              Partnership Page
            </Link>.
          </>
        ),
      },
      {
        question: "Who can I contact for more information?",
        answer: (
          <>
            For further inquiries, please reach out to us via the {" "}
            <Link to="#" onClick={() => window.open('https://zfrmz.in/boZr9Cru7BVFkZsqt5dV', '_self')} style={{ color: "blue", textDecoration: "underline" }}>
            Contact form
            </Link> on our website, and our team will be happy to assist you.
          </>
        ),
      },
      {
        question: "Are there career opportunities at Iron Lady?",
        answer: (
          <>
            We are always on the lookout for passionate individuals to join our mission of empowering women leaders.
            To explore current openings and apply, please visit our{" "}
            <Link to="#" onClick={() => window.open('https://careers.iamironlady.com/jobs/Careers', '_self')} style={{ color: "blue", textDecoration: "underline" }}>
              Careers Page
            </Link>.
          </>
        ),
      },
      {
        question: "What does Iron Lady offer through partnerships?",
        answer: (
          <>
            We collaborate with organizations to empower women, strengthen brands, and create real impact.
            To learn more and partner with us, visit:{" "}
            <Link to="/partnership" style={{ color: "blue", textDecoration: "underline" }}>
              Iron Lady Partnerships
            </Link>.
          </>
        ),
      },
      {
        question: "What corporate programs does Iron Lady offer?",
        answer: (
          <>
            We offer workshops, training sessions, mentorship programs, and team-building activities to help women grow into strong leaders.
            To learn more and partner with us, visit our{" "}
            <Link to="/corporateprogram" style={{ color: "blue", textDecoration: "underline" }}>
              Corporate Programs Page
            </Link>.
          </>
        ),
      },
      {
        question: "How can my organization get involved?",
        answer: (
          <>
            It’s simple! Visit our{" "}
            <Link to="/corporateprogram" style={{ color: "blue", textDecoration: "underline" }}>
              Corporate Programs Page
            </Link>
            , hit the "Book a Consultation" button, fill out the form, and our team will connect with you. Or, you can reach out to us directly here:{" "}
            <Link to="/contactUs" style={{ color: "blue", textDecoration: "underline" }}>
              Contact Us
            </Link>.
          </>
        ),
      },
      {
        question: "How is Iron Lady different from other leadership programs for women?",
        answer: (
          <>
            We are India’s No.1 leadership platform for women. With our approach, 100 women reach ₹1 Cr+ in yearly income through our strategic course material, expert mentors, and real-world application of leadership tactics.
            Our practical, action-driven programs set us apart by teaching women not just leadership concepts, but how to apply them in real life for real results.
          </>
        ),
      },
      {
        question: "Has Iron Lady been featured in the media?",
        answer: (
          <>
            Yes! Iron Lady has been recognized for its impact on women’s leadership and empowerment.
            To know more, check out this page:{" "}
            <Link to="#" onClick={() => window.open('https://aninews.in/news/business/global-leaders-from-iron-lady-come-together-to-celebrate-the-shakti-within-indian-women-and-announce-50-under-50-awards20241122120704/', '_self')} style={{ color: "blue", textDecoration: "underline" }}>
              ANI News Coverage
            </Link>.
          </>
        ),
      },
      {
        question: "Why does Iron Lady focus specifically on women in leadership?",
        answer: (
          <>
            Women have been giving their 100%, yet they remain overlooked and undervalued. For centuries, they’ve faced barriers that kept them out of leadership. Iron Lady exists to break these norms, challenge the status quo, and prove that women can rise, lead, and dominate.
            Our mission? A million women at the top.
          </>
        ),
      },
      {
        question: "How is Iron Lady different from other leadership programs for women?",
        answer:
          "We are India’s No.1 leadership platform for women, with our approach, 100 women reach ₹1 Cr+ in yearly income through our strategic course material, expert mentors, and real-world application of leadership tactics. Our practical, action-driven programs set us apart by teaching women not just leadership concepts, but how to apply them in real life for real results.",
      },
      {
        question: "How can I become a trainer for the Iron Lady programs?",
        answer: (
          <>
            If you’re interested in becoming a trainer, you can express your interest by contacting our team through the website. You can also send us an email or reach out via call to discuss opportunities.
            Get in touch here:{" "}
            <Link to="/contactUs" style={{ color: "blue", textDecoration: "underline" }}>
              Contact Us
            </Link>.
          </>
        ),
      },
      {
        question: "Can I provide feedback on the training sessions?",
        answer: (
          <>
            Absolutely! We value your feedback, as it helps us improve our training sessions and ensure they continue to meet the needs of women in leadership.
            To share your feedback, visit:{" "}
            <Link to="/contactUs" style={{ color: "blue", textDecoration: "underline" }}>
              Contact Us
            </Link>.
          </>
        ),
      },
      {
        question: "In which cities have women joined Iron Lady?",
        answer: (
          <>
            Women from across India—Hyderabad, Kolkata, Bangalore, Pune, Mumbai, and many more cities—have joined Iron Lady, building a powerful network of leaders and change-makers.
            Become a part of the movement! Join the Iron Lady Community here:{" "}
            <Link to="/latest" style={{ color: "blue", textDecoration: "underline" }}>
              Iron Lady Community
            </Link>.
          </>
        ),
      },
      {
        question: "What do I have to do to be part of the community?",
        answer: (
          <>
            To join the Iron Lady community, you need to enroll in our leadership programs. As you grow personally and professionally through our courses, you automatically become part of our thriving network of women leaders.
            Explore our programs here:{" "}
            <Link to="/latest" style={{ color: "blue", textDecoration: "underline" }}>
              Join the Community
            </Link>.
          </>
        ),
      },
      {
        question: "Do we have an Iron Lady community in Kolkata?",
        answer: (
          <>
            Yes! The Iron Lady community is thriving in Kolkata, and we also have strong communities in Bangalore, Mumbai, Pune, Hyderabad, and Chennai.
            Check out our community and see how you can be a part of it here:{" "}
            <Link to="/latest" style={{ color: "blue", textDecoration: "underline" }}>
              Iron Lady Community
            </Link>.
          </>
        ),
      },
      {
        question: "Where has Iron Lady conducted workshops?",
        answer: (
          <>
            Iron Lady has hosted leadership workshops in major cities across India, including Kolkata, Chennai, Hyderabad, Pune, Bangalore, and Mumbai.
            To know more about our workshops and upcoming events, visit{" "}
            <Link to="#" onClick={()=> window.open('https://events.iamironlady.com', '_self')} style={{ color: "blue", textDecoration: "underline" }}>
              Event Page
            </Link>.
          </>
        ),
      },
    ],
    program: [
      {
        question: "What types of programs does Iron Lady offer?",
        answer: (
          <>
            Iron Lady offers various leadership programs, including the Leadership Essentials Program,
            100 Board Members Program, and Master of Business Warfare. In addition to these, we offer
            workshops and masterclasses to help women develop leadership skills, master negotiations,
            and navigate workplace challenges. To know more, check out our{" "}
            <Link to="/programs" style={{ color: "blue", textDecoration: "underline" }}>
              Programs Page
            </Link>.
          </>
        ),
      },
      {
        question: "Does Iron Lady offer scholarships?",
        answer: (
          <>
            Yes, Iron Lady offers scholarships to support women in their leadership journeys. We provide financial assistance to eligible candidates for our programs.
            To learn more about the available scholarships and the application process, please visit our{" "}
            <Link to="/scholarship" style={{ color: "blue", textDecoration: "underline" }}>
              Scholarship Page
            </Link>.
          </>
        ),
      },
      {
        question: "What is Iron Lady’s Referral Program?",
        answer: (
          <>
            Yes! We offer a Referral Program (Iron Lady Champions) where you can play a key role in bringing more women into leadership. By referring others, you help build a powerful network and contribute to our mission of enabling "A Million Women at the Top."
            Check out our{" "}
            <Link to="/champions" style={{ color: "blue", textDecoration: "underline" }}>
              Referral Page
            </Link>{" "}
            to learn more!
          </>
        ),
      },
      {
        question: "What is the C-Suite League?",
        answer: (
          <>
            The C-Suite League is for ambitious leaders ready to step into the C-suite. It helps you break barriers, build influence, and develop the skills needed to lead at the top.
            Learn more about the{" "}
            <Link to="#" onClick={() => window.open('https://www.csuiteleague.com', '_self')} style={{ color: "blue", textDecoration: "underline" }}>
              C-Suite League
            </Link>.
          </>
        ),
      },
      {
        question: "Is the program theoretical or practical?",
        answer: (
          <>
            All of Iron Lady's programs are highly practical. The strategies you learn are designed for real-world career challenges and must be applied to your own professional journey.
            To know more, check out our{" "}
            <Link to="/programs" style={{ color: "blue", textDecoration: "underline" }}>
              Programs Page
            </Link>.
          </>
        ),
      },
      {
        question: "What kind of workshops does Iron Lady offer?",
        answer:
          "Iron Lady offers a variety of workshops designed to help women succeed. Focused on leadership, strategic thinking, and career growth, these workshops equip women with the skills to rise to the top and lead with confidence.",
      },
      {
        question: "Has Iron Lady hosted any events?",
        answer: (
          <>
            Yes, Iron Lady has hosted many events, each designed to fast-track women's leadership journeys and create powerful networking opportunities.
            For updates on past events, check out our{" "}
            <Link to="#" onClick={()=> window.open('https://events.iamironlady.com', '_self')} style={{ color: "blue", textDecoration: "underline" }}>
              Event Page
            </Link>.
          </>
        ),
      },
      {
        question: "Are there any upcoming events for Iron Lady?",
        answer: (
          <>
            Yes! There are many exciting events lined up. Stay updated by checking out our{" "}
            <Link to="#" onClick={()=> window.open('https://events.iamironlady.com', '_self')} style={{ color: "blue", textDecoration: "underline" }}>
              Events Page
            </Link>.
          </>
        ),
      },
      {
        question: "Is there any case study where I can see how Iron Lady changes lives?",
        answer: (
          <>
            Yes! Many women have shared their inspiring journeys and the transformations they experienced after joining Iron Lady. From career breakthroughs to leadership success, their stories speak for themselves.
            To read more, check out our{" "}
            <Link to="/impact" style={{ color: "blue", textDecoration: "underline" }}>
              Case Study
            </Link> {" "} and {" "}
            <Link to="/resources/video-resources" style={{ color: "blue", textDecoration: "underline" }}>
              Video Resources Page
            </Link>
          </>
        ),
      },
      {
        question: "Does Iron Lady offer scholarships?",
        answer: (
          <>
            Yes! Iron Lady offers scholarships to support women in leadership.
            To learn more and apply, visit our{" "}
            <Link to="/scholarship" style={{ color: "blue", textDecoration: "underline" }}>
              Scholarship Page
            </Link>.
          </>
        ),
      },
      {
        question: "How can I stay updated with Iron Lady news?",
        answer: (
          <>
            To stay updated with Iron Lady, subscribe to our {" "}
            <Link to="/newsletter" style={{ color: "blue", textDecoration: "underline" }}>
              Newsletter
            </Link> and follow us on social media. Stay in the loop with our latest programs, events, and leadership insights.
          </>
        ),
      },
      {
        question: "Does Iron Lady offer a 2-day Masterclass?",
        answer: (
          <>
            Yes, we offer a 2-day Masterclass designed for impactful learning.
            To know more, check out our{" "}
            <Link to="#" onClick={()=> window.open("https://workshops.iamironlady.com/masterclass_app", "_self")} style={{ color: "blue", textDecoration: "underline" }}>
              Masterclass Page
            </Link>.
          </>
        ),
      },
      {
        question: "Is there a refund policy if I feel the program isn’t right for me?",
        answer: (
          <>
            Yes, the Iron Lady Leadership Essentials Program offers a refund policy. If you feel the program isn't right for you, you can request a refund, but terms and conditions apply.
            For full details, visit our{" "}
            <Link to="/individualPrograms/Leadership_Essentials_Program/policy" style={{ color: "blue", textDecoration: "underline" }}>
              Refund Policy
            </Link>{" "}
            page.
          </>
        ),
      },
      {
        question: "Can I transfer my seat to someone else or a different batch?", 
        answer: (
          <>
            No, once a seat is assigned to a batch, it cannot be carried forward to another batch or transferred to another person. {" "}
            <Link to="/contactUs" style={{ color: "blue", textDecoration: "underline" }}>
              Contact Us
            </Link> {" "} if you need any further clarification.
          </>
        ),
      },
      {
        question: "What happens if I discontinue the program?",
        answer: (
          <>
            If you choose to leave the program midway for personal reasons, unfortunately, a refund won’t be possible.
            You can check the full details here:{" "}
            <Link to="/individualPrograms/Leadership_Essentials_Program/policy" style={{ color: "blue", textDecoration: "underline" }}>
              Refund & Reschedule Policy
            </Link>.
          </>
        ),
      },
      {
        question: "Will I get a refund if I participate in the program for only one day/week?",
        answer: (
          <>
            No, attending for just a day or a week doesn’t qualify for a refund. If a participant is removed for not following the program guidelines or due to lack of participation, a refund won’t be possible.
            You can find all the details in our{" "}
            <Link to="#" style={{ color: "blue", textDecoration: "underline" }}>
              Refund & Reschedule Policy
            </Link>.
          </>
        ),
      },
      {
        question: "What do participants say about Iron Lady?",
        answer: (
          <>
            Our participants have seen real changes in their careers and confidence. Don’t take our word for it—see what they have to say!
            Watch their stories here:{" "}
            <Link to="/resources/video-resources" style={{ color: "blue", textDecoration: "underline" }}>
              Participant Stories
            </Link>.
          </>
        ),
      },
      {
        question: "Does Iron Lady have a blog page?",
        answer: (
          <>
            Yes! Our blog dives into leadership strategies, career growth, workplace challenges, and inspiring success stories—all designed to help women rise to the top.
            Explore our articles here:{" "}
            <Link to="/blogs" style={{ color: "blue", textDecoration: "underline" }}>
              Iron Lady Blog
            </Link>.
          </>
        ),
      },
      {
        question: "Who can participate in Iron Lady programs?",
        answer: (
          <>
            Our programs are designed for women from all walks of life—whether you're a working professional feeling stuck in your career, a woman looking to restart your professional journey, an entrepreneur, or someone aiming for a leadership role.
            Choose your program and take the next step:{" "}
            <Link to="/programs" style={{ color: "blue", textDecoration: "underline" }}>
              Check out our programs here
            </Link>.
          </>
        ),
      },
      {
        question: "How can I register for a program?",
        answer: (
          <>
            It’s easy! Just head over to the{" "}
            <Link to="/programs" style={{ color: "blue", textDecoration: "underline" }}>
              Programs
            </Link> page, pick the program that fits you, click "Apply for the Program," fill out the form, and hit submit. You'll get a confirmation email, and our team will connect with you!
          </>
        ),
      },
      {
        question: "How long do the programs last?",
        answer: (
          <>
            It depends on the programs and workshops! For more details, check our{" "}
            <Link to="/programs" style={{ color: "blue", textDecoration: "underline" }}>
              Programs Page
            </Link>, where you’ll find the duration for each program.
          </>
        ),
      },
      {
        question: "What is the 'Champions' program at Iron Lady?",
        answer: (
          <>
            The Champions program is our way of recognizing and uplifting those who actively support women’s leadership and career growth.
            Want to be a part of it? Learn more about the{" "}
            <Link to="/champions" style={{ color: "blue", textDecoration: "underline" }}>
              Champions Program
            </Link>.
          </>
        ),
      },
      {
        question: "How can I find the right program for me?",
        answer: (
          <>
            Explore our{" "}
            <Link to="/programs" style={{ color: "blue", textDecoration: "underline" }}>
              Programs Page
            </Link>{" "}
            to find detailed information about each program. If you need personalized guidance, feel free to{" "}
            <Link to="/contactUs" style={{ color: "blue", textDecoration: "underline" }}>
              Contact Us
            </Link>.
          </>
        ),
      },
      {
        question: "Are there any prerequisites for enrolling in these programs?",
        answer:
          "While specific prerequisites may vary by program, our courses are generally designed to accommodate women at different stages of their careers. Detailed requirements are provided on each Programs Page.",
      },
      {
        question: "What is the Iron Lady Learning Management System (LMS)?",
        answer:
          "The Iron Lady LMS is an exclusive online platform designed specifically for Iron Lady participants. Here, you can access course materials, educational resources, and video recordings of program elements—all in one place for continuous learning.",
      },
      {
        question: "When is the next Masterclass scheduled?",
        answer: (
          <>
            You can check our website for the latest updates on upcoming Masterclass dates.
            Stay updated here:{" "}
            <Link to="#" onClick={()=> window.open("https://workshops.iamironlady.com/masterclass_app", "_self")} style={{ color: "blue", textDecoration: "underline" }}>
              Iron Lady Masterclass
            </Link>.
          </>
        ),
      },
      {
        question: "Is there a fee to attend the Masterclass?",
        answer: (
          <>
            Yes! There is a nominal fee to join this transformative experience. This ensures that only highly motivated women participate and make the most of the program.
            Secure your spot here:{" "}
            <Link to="#" onClick={()=> window.open("https://workshops.iamironlady.com/masterclass_app", "_self")} style={{ color: "blue", textDecoration: "underline" }}>
              Iron Lady Masterclass
            </Link>.
          </>
        ),
      },
      {
        question: "Who can participate in the Masterclass?",
        answer: (
          <>
            The Masterclass is for any woman who wants to develop leadership skills and accelerate her career. Whether you’re aiming for a promotion or looking for a career breakthrough—this is for you!
            Join the Masterclass here:{" "}
            <Link to="#" onClick={()=> window.open("https://workshops.iamironlady.com/masterclass_app", "_self")} style={{ color: "blue", textDecoration: "underline" }}>
              Iron Lady Masterclass
            </Link>.
          </>
        ),
      },
      {
        question: "How do Iron Lady programs help women in their careers?",
        answer:
          "We provide expert-designed courses that focus on real-life challenges women face, helping them build strategic leadership skills to navigate and lead in both professional and personal environments with confidence.",
      },
      {
        question: "Can entrepreneurs benefit from Iron Lady programs?",
        answer: (
          <>
            Absolutely! Many entrepreneurs who joined Iron Lady have gone on to build successful businesses using our strategies.
            Our programs provide powerful techniques, real-world business tactics, and high-impact networking opportunities to help women scale and grow their ventures.
            Hear from our past entrepreneurs and their success stories:{" "}
            <Link to="/impact" style={{ color: "blue", textDecoration: "underline" }}>
              Impact Stories
            </Link>.
          </>
        ),
      },
    ],
  };

  const [expandedQuestion, setExpandedQuestion] = useState(null);

  const handleSectionClick = (section) => {
    setActiveSection(section);
    setExpandedQuestion(null);
  };

  const handleQuestionClick = (index) => {
    setExpandedQuestion(expandedQuestion === index ? null : index);
  };

  return (
    <div className={Styles.faqContainer}>
      <h1 className={Styles.faqHeading}>Frequently Asked Questions</h1>

      <div className={Styles.sectionButtons}>
        <button
          className={`${Styles.sectionButton} ${activeSection === "brand" ? Styles.active : ""}`}
          onClick={() => handleSectionClick("brand")}
        >
          Brand
        </button>
        <button
          className={`${Styles.sectionButton} ${activeSection === "program" ? Styles.active : ""}`}
          onClick={() => handleSectionClick("program")}
        >
          Programs
        </button>
      </div>

      <div className={Styles.accordionContainer}>
        {faqData[activeSection].map((faq, index) => (
          <div key={index} className={Styles.accordionItem}>
            <div
              className={Styles.accordionQuestion}
              onClick={() => handleQuestionClick(index)}
            >
              <h3>{index + 1}. {faq.question}</h3>
              <span className={Styles.accordionIcon}>
                {expandedQuestion === index ? "−" : "+"}
              </span>
            </div>
            {expandedQuestion === index && (
              <div className={Styles.accordionAnswer}>
                <p>{faq.answer}</p>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQ;