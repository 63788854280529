import { urls } from "../utils/urls";

export const appLevelConstants = {
  Header: {
    headers: [
      { id: 1, to: "/", name: "Home" },
      { id: 2, to: "/programs", name: "Programs" },
      { id: 5, to: "/impact", name: "Impact" },
      { id: 6, to: "/", name: "About Us" },
      { id: 9, to: "/", name: "Resources"},
      { id: 9, to: "/", name: "Contact Us"},
      { id: 7, to: "/latest", name: "Latest" },
      { id: 8, to: "/", name: "MasterClass" },
    ],
    // headers: [
    //   { id: 1, to: "/", name: "Home" },
    //   { id: 2, to: "/programs", name: "Programs" },
    //   { id: 5, to: "/impact", name: "Impact" },
    //   { id: 6, to: "/aboutUs", name: "About Us" },
    //   { id: 7, to: "/latest", name: "Latest" },
    //   { id: 8, to: " ", target: "_blank", name: "MasterClass" },
    // ],
  },

  HeaderDropdown: {
    programsDropdown: [
      {
        id: 1,
        to: "/individualPrograms",
        title: "Leadership Essentials Program",
      },
      { id: 2, to: "/individualPrograms", title: "100 Board Members Program" },
      { id: 3, to: "/individualPrograms", title: "Master of Business Warfare" },
    ],
  },
  Programs: [
    "/individualPrograms/Leadership_Essentials_Program",
    "/individualPrograms/100_Board_Members_Program",
    "/individualPrograms/Master_of_Business_Warfare",
  ],
  headerLogo: [
    "/programs",
    "/impact",
    "/resources/video-resources",
    "/aboutUs",
    "/individualPrograms/Leadership_Essentials_Program",
    "/individualPrograms/100_Board_Members_Program",
    "/individualPrograms/Master_of_Business_Warfare",
    "/latest",
  ],
  Home: {
    MainTitle: {
      mainTitle: [
        { id: 1, title: "ELEVATING A MILLION" },
        { id: 2, title: "WOMEN TO" },
        { id: 3, title: "THE TOP" },
      ],
      smallParagraph: {
        title1: "Using Business Warfare tactics",
        title2: "Through Strength Obsessed Community",
        title3: "With Programs designed by Global CEOs/practitioners",
        title4: "Tailored for Individual Growth",
      },
    },
    ExploreMore: { title: "Explore Programs" },
  },
  breakThrough:
    "Let’s onboard you on the Fastrack Growth journey with Iron Lady",
  trustedWoman: {
    trustedWomanTitle: "Who is it for?",
    trustedWoman: [
      {
        id: 1,
        img: urls.trustedWomen1,
        description: "Professionals aspiring for growth",
        mobileDescription: "Professionals aspiring for growth",
      },
      {
        id: 2,
        img: urls.trustedWomen2,
        description: "Entrepreneurs / Business Women / self-employed",
        mobileDescription: "Entrepreneurs / Business Women / self-employed",
      },
      {
        id: 3,
        img: urls.trustedWomen3,
        description: "Women seeking Career Change / restart",
        mobileDescription: "Women seeking Career Change / restart",
      },
    ],
  },

  LeadershipCourses: {
    title: "What We Offer",
    leadershipCourses: [
      {
        id: 1,
        title: "Leadership Essentials Program",
        duration: "6 Weeks | Online",
        des: [
          {
            id: 1,
            des: "Are you often asked - “Learn to BALANCE ?",
          },
          {
            id: 2,
            des: "Do you feel “Guilty” about being Ambitious?",
          },
          {
            id: 3,
            des: "Learn the art of maximising, shameless pitching and deal with office politics and biases. Be unapologetically ambitious.",
          },
        ],
        knowMore: "Know more",
        description:
          "Are you often asked - “Learn to BALANCE ? Do you feel “Guilty” about being Ambitious? Learn the art of maximising, shameless pitching and deal with office politics and biases. Be unapologetically ambitious.",
        to: "/individualPrograms/Leadership_Essentials_Program",
      },
      {
        id: 2,
        title: "100 Board Members Program",
        duration: "-",
        des: [
          {
            id: 1,
            des: "Have you been feeling stuck at the same level in your career and no tactics are working?",
          },
          {
            id: 2,
            des: "Enrol for 100 Board Member Program to learn innovative techniques to fastrack your overdue growth.",
          },
        ],
        knowMore: "Know more",
        description:
          "Have you been feeling stuck at the same level in your career and no tactics are working? Enrol for 100 Board Member Program to learn innovative techniques to fastrack your overdue growth.",
        to: "/individualPrograms/100_Board_Members_Program",
      },
      {
        id: 3,
        title: "Master of Business Warfare",
        duration: "-",
        des: [
          {
            id: 1,
            des: "Are you committed to reach the C-suite, but don’t know how?",
          },
          {
            id: 2,
            des: "Is 1+ Crore Income your dream band?",
          },
          {
            id: 3,
            des: "Join our Master of Business Warfare program to understand cutting-edge business warfare tactics for these breakthroughs in your career.",
          },
        ],
        knowMore: "Know more",
        description:
          "Are you committed to reach the C-suite, but don’t know how? Is 1+ Crore Income your dream band? Join our Master of Business Warfare program to understand cutting-edge business warfare tactics for these breakthroughs in your career.",
        to: "/individualPrograms/Master_of_Business_Warfare",
      },
    ],
  },

  SuggestPrograms: {
    quote:
      '"This program has enables me to develop me to develop my leadership skills altogether at a defferent level. Every person around me appreciates the positive contributions that the program has made in my life. My reading speed and my ability to learn has incrased multi-fold through the program. My ability to grasp things, comminicate, and live with a sense of purpose has gone through the roof."',
    mainTitle: {
      title: "Not sure which",
      title1: "program to choose? ",
    },
    voiceOfIL: "Voice of our Iron Ladies",
    userName: "Vinaya Seth",
  },

  SuggestPrograms_Hindi: {
    mainTitle: {
      title: "पक्का नहीं कर पा रहे हैं कि",
      title1: "कौन सा कार्यक्रम चुनें?",
    },
  },

  aboutUs: {
    mainContent: "Iron Lady",
    mainContent1: " is the leading Leadership Platform for women in India. ",
    mainContent2:
      "High Impact Leadership programs combined with a strength obsessed community of top women leaders in India, make Iron Lady ‘THE Go To’ Destination for women aspiring to grow in their careers / businesses.  ",
    mainContent3: "Thousands of Iron Ladies have gone onto to become top ",
    mainContent4:
      " and are now a part of this highly engaging and high impact platform.",
    mainContent7:
      "Rajesh Bhat,Founder & Director, Iron Lady, became the only man to speak on TEDx Platform in a Saree.. Listen to him share about How Women can win without Waging War.",

    ourStoryTitle: "Our journey so far",
    ourStories: [
      {
        "title": "2024: A Year of Unstoppable Growth",
        "description": [
          {
            "id": 1,
            "value": "100 Iron Lady participants reached ₹1 crore+ yearly income."
          },
          {
            "id": 2,
            "value": "7,000+ professionals secured well-deserved promotions."
          },
          {
            "id": 3,
            "value": "6,000+ professionals achieved 2X salary growth."
          },
          {
            "id": 4,
            "value": "We hosted 20+ global events, taking Shakti 2.0 to the world stage."
          },
          {
            "id": 5,
            "value": "Launched the C-Suite League, empowering leaders to fast-track their rise to the top."
          }
        ]
      },      
      {
        title: "2023:  Mission Million Women at the TOP!​",
        description: [
          {
            id: 1,
            value:
              "We are thrilled to evolve alongside the evolving needs of our community.",
          },
          {
            id: 2,
            value:
              "Our commitment to supporting women is evident through our diverse range of offerings.",
          },
          {
            id: 3,
            value: "Explore all our offerings on our",
            home: " Home Page.",
          },
        ],
      },
      {
        title: "2021-23: The No.1 Platform for Women in India",
        description: [
          {
            id: 1,
            value:
              "Scaling of all the programs and impact including 100 Board Members.",
          },
          {
            id: 2,
            value: "Creation Master of Business Warfare (1 Crore + Club).",
          },
          {
            id: 3,
            value:
              "A total of 78,000+ people trained through various programs.",
          },
        ],
      },
      {
        title: "2019-2020: The digital / online cohorts​",
        description: [
          {
            id: 1,
            value:
              "Beginning various cohorts across India and online programs.",
          },
          {
            id: 2,
            value:
              "20,000+ people trained through various programs, with huge success.",
          },
          {
            id: 3,
            value: "Created the 100 Board Members Program.",
          },
        ],
      },
      {
        title: "2018-19: Programs across India, huge success of participants ​",
        description: [
          {
            id: 1,
            value:
              "Program cohorts across India were conducted with much larger scale.",
          },
          {
            id: 2,
            value:
              "Women reported having seen tremendous success using the Tactics.",
          },
        ],
      },
      {
        title: "2016-17: Coming together, Initial Programs​",
        description: [
          {
            id: 1,
            value:
              "Founding team of Global CEOs like Simon, Sridhar, Chitra, Suvarna, Rajesh came together to start Iron Lady.",
          },
          {
            id: 2,
            value:
              "Created ‘Iron Lady Way’ Methodology for Women, based on all the experience.",
          },
          {
            id: 3,
            value: "Initial Programs conducted.",
          },
        ],
      },
      {
        title: "2011-2016: Personal journeys of Founding team",
        description: [
          {
            id: 1,
            value:
              "Rajesh Bhat had scaled Head Held High Foundation, impacting lakhs of lives.",
          },
          {
            id: 2,
            value:
              "Suvarna Hegde went through a transformational journey of exponential 4-times growth within 3 years.",
          },
          {
            id: 3,
            value:
              "Other founding team members had been exploring ways of making a bigger impact.",
          },
        ],
      },
    ],
    whatDifference: [
      {
        id: 1,
        img: urls.image1,
        title: "Win with Business War Tactics",
        description: [
          {
            id: 1,
            value:
              "Stereotypes, biases, politics associated with being a WOMAN are common in the business world. Every day becomes like a war for women.",
          },
          {
            id: 2,
            value:
              "Our Business War Tactics enable women to learn to Win without even Fighting! ",
          },
        ],
      },
      {
        id: 2,
        img: urls.image2,
        title: "Achieve Breakthrough Fast-track Growth",
        description: [
          {
            id: 1,
            value:
              "Combining 'breakthrough' and 'results-focused' approach, thinking unconventionally, taking risks, and implementing strategies that generate transformative results in the smallest possible time.",
          },
        ],
      },
      {
        id: 3,
        img: urls.image3,
        title: "Join 78,000+ Women Leaders’ Ecosystem",
        description: [
          {
            id: 1,
            value:
              "Iron Lady Communities are non-judgmental. They share ambitions and celebrate each other’s ambitions and successes during the celebration and learning sessions.",
          },
          {
            id: 2,
            value:
              "They share secret Business War tactics and formulas of success with each other! ",
          },
        ],
      },
      {
        id: 4,
        img: urls.image4,
        title: "Develop an Unapologetic Winning Mindset",
        description: [
          {
            id: 1,
            value:
              "Balancing is the most often sugarcoated word used often describe how women should “compromise”, “adjust”, “suffer”, and “stop dreaming”. ",
          },
          {
            id: 2,
            value:
              "We enable women to develop a mindset towards “Winning”. Winning doesn’t mean others need to lose! ",
          },
        ],
      },
      {
        id: 5,
        img: urls.image5,
        title: "Learn from Global Practitioners' expertise",
        description: [
          {
            id: 1,
            value:
              "Our content is created, used, and practiced by global practitioners, entrepreneurs and CEOs who have included their personal learnings, and experiences / techniques templates for the winning methodologies and strategies.",
          },
        ],
      },
    ],
  },

  impact: {
    impactTitle1: "78,000+ women have gone through Iron Lady Programs so far. ",
    impactTitle2: "Thousands of have gone onto become top",
    impactTitle3: "using Iron Lady Principles.",
  },
  program: {
    programInformation: [
      {
        id: 1,
        title: "Leadership Essentials Program",
        duration: "6 Weeks Program with 2 Full Day Sessions",
        image: urls.individualProgram1,
        value1:
          "Are you often told by people to “Balance” and “Not Be So Ambitious”?",
        value2:
          "Wouldn’t you rather Maximize and pitch your ambitions Unapologetically!!",
        description:
          "This program teaches the core principles of shameless pitching, strategic maximization, and cultivating unwavering confidence. Gain the tools to navigate office politics, overcome biases, and emerge as a powerful leader at workplace.",
        to: "/individualPrograms/Leadership_Essentials_Program",
      },
      {
        id: 2,
        title: "100 Board Members Program",
        duration: "6 Months Online Program with Weekly Q&A Sessions",
        image: urls.individualProgram2,
        value1: "Are you stuck at the same level or income in your career?",
        value2:
          "What if you could Fastrack your overdue growth in 6-18 months!!",
        description:
          "This program is for mid-level career women professionals who are eagerly waiting for their next big win. Experience the career breakthroughs you've been striving for but haven't quite reached. Fastrack your growth with innovative techniques  and practical approaches.",
        to: "/individualPrograms/100_Board_Members_Program",
      },
      {
        id: 3,
        title: "Master of Business Warfare",
        duration: "1 Year Program with Bi-Weekly Sessions",
        image: urls.individualProgram3,
        value1:
          "Does moving to C-suite and 1 Crore+ band sound like a distant yet aspirational dream?",
        value2:
          "But with changing your leadership approach you can make it happen and strive at it.",
        description:
          "This program equips senior professionals  with the right strategies to not just enter the C-suite but thrive in that role. Adopt a competitor-centric and market-centric approach that will give you unparalleled growth and  help you reach your aspirational role.",
        to: "/individualPrograms/Master_of_Business_Warfare",
      },
    ],
    programs: [
      {
        id: 1,

        img: urls.rajesh,

        name: "RAJESH BHAT",

        post: "FOUNDER & DIRECTOR",

        content:
          "<b>Rajesh</b>, a visionary entrepreneur, has led three groundbreaking startups: <b>Head Held High, 1Bridge, and Iron Lady</b>. Beyond his business successes, Rajesh's unconventional leadership style and commitment to gender equality have gained global recognition. His bold choice to wear a saree in professional settings has sparked conversations and challenged stereotypes, amplifying the discourse on gender equality. Rajesh's innovative leadership has earned him accolades from influential figures like <b>Amitabh Bachchan</b> and <b>CNN</b>, who named him one of <b>'The Real Heroes of India.'</b>",
        linkedin: "https://www.linkedin.com/in/meetrajeshbhat/",
      },

      {
        id: 2,

        img: urls.suverna,

        name: "SUVARNA HEGDE",

        post: "FOUNDER & CEO",

        content:
          "<b>Suvarna</b> is one of the foremost experts of Business War Tactics for Women. She has coached hundreds of Founders / CEOs and thousands of women leaders to reach the TOP. Suvarna's own experiences and expertise led to the creation of <b>Iron Lady - in 2016-17.</b> As an innovation specialist, she has created multiple mega Technology Projects in well-known companies like <b>Infosys, Robert Bosch and Philips</b>, before starting <b>Iron Lady.</b>",
        linkedin: "https://www.linkedin.com/in/suvarna-hegde-6ab36534/",
      },

      {
        id: 3,

        img: urls.simon,

        name: "SIMON NEWMAN",

        post: "CO-FOUNDER, CHAIRMAN",

        content:
          "<b>Simon</b> is a highly accomplished global CEO / CXOs – being the CEO of companies like <b>Aviva, Singapore.</b> He’s held CXO positions at <b>Barclays, Prudential and Hong Leong Bank</b>. Simon has mentored / managed / supported thousands of senior leaders over his illustrious career spanning over 3 decades. ",
        linkedin:
          "https://www.linkedin.com/in/simon-newman-8132115/?originalSubdomain=uk",
      },

      {
        id: 4,

        img: urls.sridhar,

        name: "SRIDHAR SAMBANDAM",

        post: "CO-FOUNDER, DIRECTOR",

        content:
          "<b>Sridhar</b> is considered one of the foremost turn-around specialists in India, having turned around many billion-dollar companies like <b>Bajaj Auto and Escorts</b>, being their CEO / president. Sridhar comes with 25+ years of corporate experience. Sridhar coaches many CEOs / entrepreneurs of large companies today. He is a specialist in the Bing-Fa and Art of War corporate breakthrough performance methodologies.",
        linkedin: "https://www.linkedin.com/in/sridhar-s-03340792/",
      },
      {
        id: 5,
        img: urls.chitra,
        name: "CHITRA TALWAR",
        post: "INVESTOR, BOARD MEMBER AND ADVISOR: SOCIAL IMPACT CHAMPION!",

        content:
          "With over three decades of global experience in the FMCG sector, She has held key leadership positions at Britannia and PepsiCo across Sales, Marketing, and General Management in both Indian and international markets. Based in New York, she retired as Vice President, International Sales Operations at PepsiCo, NY. She has a proven track record of scaling global initiatives and driving impactful social solutions, approaching challenges head-on with a strong focus on deliverables while bringing her unique leadership style rooted in empathy. A Gold Medalist graduate in Economics from Madras University and an MBA from XLRI (Class of 1976), she combines strong operational expertise with a keen business acumen.",
        linkedin: "https://www.linkedin.com/in/chitra-talwar-3b936521/",
      },
    ],
    program_curators_Hindi: [
      {
        id: 1,
        img: urls.rajesh,
        name: "राजेश भट",
        post: "संस्थापक और निदेशक",
        content:
          "<b>राजेश</b>, एक दूरदर्शी उद्यमी, ने तीन क्रांतिकारी स्टार्टअप्स का नेतृत्व किया है: <b>हेड हेल्ड हाई, 1ब्रिज, और आयरन लेडी</b>। अपने व्यावसायिक सफलताओं के अलावा, राजेश की अपरंपरागत नेतृत्व शैली और लैंगिक समानता के प्रति प्रतिबद्धता ने उन्हें वैश्विक पहचान दिलाई है। उनके साहसी निर्णय, जैसे पेशेवर सेटिंग्स में साड़ी पहनना, ने संवाद को उत्प्रेरित किया है और रूढ़ियों को चुनौती दी है, लैंगिक समानता पर बहस को और प्रबल किया है। राजेश के नवाचारात्मक नेतृत्व को प्रमुख हस्तियों जैसे <b>अमिताभ बच्चन</b> और <b>CNN</b> से सराहना मिली है, जिन्होंने उन्हें <b>'भारत के वास्तविक नायकों'</b> में से एक के रूप में नामांकित किया।",
        linkedin: "https://www.linkedin.com/in/meetrajeshbhat/"
      },
      {
        id: 2,
        img: urls.suverna,
        name: "सुवर्णा हेगड़े",
        post: "संस्थापक और सीईओ",
        content:
          "<b>सुवर्णा</b> महिलाओं के लिए व्यवसाय युद्ध रणनीतियों की अग्रणी विशेषज्ञों में से एक हैं। उन्होंने सैकड़ों संस्थापकों / सीईओ और हजारों महिला नेताओं को शीर्ष तक पहुंचने के लिए मार्गदर्शन किया है। सुवर्णा के अपने अनुभवों और विशेषज्ञता ने <b>आयरन लेडी - 2016-17</b> की नींव रखी। एक नवाचार विशेषज्ञ के रूप में, उन्होंने <b>इन्फोसिस, रॉबर्ट बॉश और फिलिप्स</b> जैसी प्रसिद्ध कंपनियों में कई मेगा टेक्नोलॉजी प्रोजेक्ट्स का निर्माण किया, इससे पहले कि उन्होंने <b>आयरन लेडी</b> की शुरुआत की।",
        linkedin: "https://www.linkedin.com/in/suvarna-hegde-6ab36534/"
      },
      {
        id: 3,
        img: urls.simon,
        name: "साइमन न्यूमैन",
        post: "सह-संस्थापक, अध्यक्ष",
        content:
          "<b>साइमन</b> एक अत्यधिक प्रतिष्ठित वैश्विक सीईओ/सीएक्सओ हैं – <b>अवीवा, सिंगापुर</b> के सीईओ रह चुके हैं। उन्होंने <b>बार्कलेज, प्रूडेंशियल और हांग लियॉन्ग बैंक</b> में सीएक्सओ पदों पर काम किया है। साइमन ने अपने शानदार करियर के दौरान हजारों वरिष्ठ नेताओं का मार्गदर्शन, प्रबंधन और समर्थन किया है, जो तीन दशकों से अधिक का अनुभव रखते हैं।",
        linkedin: "https://www.linkedin.com/in/simon-newman-8132115/?originalSubdomain=uk"
      },
      {
        id: 4,
        img: urls.sridhar,
        name: "श्रीधर सम्बंदम",
        post: "सह-संस्थापक, निदेशक",
        content:
          "<b>श्रीधर</b> को भारत में सबसे प्रमुख परिवर्तन-विशेषज्ञों में से एक माना जाता है, जिन्होंने <b>बजाज ऑटो और एस्कॉर्ट्स</b> जैसी कई अरब डॉलर की कंपनियों का नेतृत्व किया है, जहां वे सीईओ/प्रेसिडेंट रहे हैं। श्रीधर के पास 25+ वर्षों का कॉर्पोरेट अनुभव है। आज वे बड़ी कंपनियों के सीईओ/उद्यमियों का मार्गदर्शन करते हैं। श्रीधर 'बिंग-फा' और 'आर्ट ऑफ़ वॉर' कॉर्पोरेट सफलता पद्धतियों के विशेषज्ञ हैं।",
        linkedin: "https://www.linkedin.com/in/sridhar-s-03340792/"
      },
      {
        id: 5,
        img: urls.chitra,
        name: "चित्रा तलवार",
        post: "निवेशक, बोर्ड सदस्य और सलाहकार: सामाजिक प्रभाव की चैंपियन!",
        content:
          "एफएमसीजी सेक्टर में 3+ दशकों का वैश्विक अनुभव, भारत और अंतरराष्ट्रीय बाजारों में प्रमुख भूमिकाओं में। <b>पेप्सिको, एनवाई</b> में इंटरनेशनल सेल्स ऑपरेशन्स की उपाध्यक्ष के रूप में सेवानिवृत्त। चाहे वह वैश्विक पहलों का विस्तार करना हो या गहरे सामाजिक प्रभाव समाधान देना हो, चित्रा ने चुनौतियों का सामना किया है और डिलीवेरेबल्स पर ध्यान केंद्रित किया है। 1976 में एक्सएलआरआई से स्वर्ण पदक विजेता स्नातक और एमबीए, वे संचालन में मजबूत कुशलताओं और अच्छे व्यावसायिक दृष्टिकोण का संयोजन करती हैं।",
        linkedin: "https://www.linkedin.com/in/chitra-talwar-3b936521/"
      }
    ],
    questions: [
      {
        id: 1,
        question: "Will this be 1:1 mentoring?",

        answer:
          "All the sessions of 100 Board Members Program are group sessions. However, individual challenges will be handled through QnA sessions in private unrecorded sessions.",
      },
      {
        id: 2,
        question: "Will I get additional support after the program is over?",

        answer:
          "There's a 100 Board Members community. The Program content access is provided for 6 months after the program is completed, 1 year from the date of registration.",
      },
      {
        id: 3,
        question: "What are the potential payment options?",

        answer: "We do provide easy EMI options, through our banking partners.",
      },
      {
        id: 4,

        question:
          "Is this program suitable for a self-employed professional, government sector Employees?",

        answer:
          "Yes, this program supports all Mid-senior individuals irrespective of profession, industry, sector. Some of our alumni are renowned Doctors, Lawyers, Engineers, Government-body Heads, and Small-business owners as well who have been able to change their personas with our program. This program is beneficial for everyone.",
      },
    ],

    programHome: [
      {
        id: 1,
        img: urls.individualProgram1,
        program: "Leadership Essentials Program",
        value1:
          "Are you often told by people to “Balance” and “Not Be So Ambitious”?",
        value2:
          "Wouldn’t you rather MAXIMIZE and pitch your ambitions Unapologetically!!",
        to: "/individualPrograms/Leadership_Essentials_Program",
      },
      {
        id: 2,
        img: urls.individualProgram2,
        program: "100 Board Members Program",
        value1: "Are you stuck at the same level or income in your career?",
        value2:
          "What if you could Fastrack your overdue growth in 6-18 months!!",
        to: "/individualPrograms/100_Board_Members_Program",
      },
      {
        id: 3,
        img: urls.individualProgram3,
        program: "Master of Business Warfare",
        value1:
          "Does moving to C-suite and 1 Crore+ band sound like a distant yet aspirational dream?",
        value2:
          "But with changing your leadership approach you can make it happen and strive at it.",
        to: "/individualPrograms/Master_of_Business_Warfare",
      },
    ],

    whatsDrive: [
      {
        id: 1,
        img: urls.hardHit1,
        title: "Background: Challenges women face!",
        description: [
          {
            id: 1,
            value: "Women only earn ONLY 50% - 90% of what’s earned by men.",
          },
          {
            id: 2,
            value:
              "As a result, there’s a lifetime of income inequality between men and women.",
          },
          {
            id: 3,
            value:
              "Women are constantly underrepresented at every level of organizations - from entry-level jobs to C-suite roles. And as they go higher up, the representation becomes bleaker.",
          },
        ],
      },

      {
        id: 2,

        img: urls.hardHit2,
        title: "Inadequate ‘Women focused’ Programs / Platforms",
        description: [
          {
            id: 1,
            value:
              "Most leading business schools conduct a few Programs for women. These programs and other networking platforms focus on topics like Balancing / Knowledge-building or are focused on diversity.",
          },
          {
            id: 2,
            value:
              "Unfortunately, all these initiatives and programs are unable to support women in dealing with ‘WAR like’ situations that exist for them every day in reaching the next levels.",
          },
          {
            id: 3,
            value: "The gaps have only increased over the last few years!",
          },
        ],
      },

      {
        id: 3,

        title: "Iron Lady: The leading Leadership Platform for Women in India",
        description: [
          {
            id: 1,
            value:
              "The founding team of Iron Lady has a combined Global CEO / VP / entrepreneurial experience of 120+ years.",
          },
          {
            id: 2,
            value:
              "All of them have been leading CEOs / entrepreneurs / board members of companies like Bajaj Auto, Aviva, Head Held High / Pepsi, Jana Finance, etc.",
          },
          {
            id: 3,
            value:
              "Passionate towards supporting women in fast tracking their careers, they came together to create a Purpose Driven, high-impact leadership platform that enables women to implement real-time high impact strategies to reach the TOP.",
          },
          {
            id: 4,
            value:
              "Today, Iron Lady has become the Leading Leadership Platform for Women in India.",
          },
        ],
      },
    ],
  },

  individualPrograms: {
    faq: [
      {
        id: 1,
        question: ". Will this be 1:1 mentoring?",

        answer:
          "All the sessions of 100 Board Members Program are group sessions. However, individual challenges will be handled through QnA sessions in private unrecorded sessions.",
      },
      {
        id: 2,
        question: ". Will I get additional support after the program is over?",

        answer:
          "There's a 100 Board Members community. The Program content access is provided for 6 months after the program is completed, 1 year from the date of registration.",
      },
      {
        id: 3,
        question: ". What are the potential payment options?",

        answer: "We do provide easy EMI options, through our banking partners.",
      },
      {
        id: 4,

        question:
          ". Is this program suitable for a self-employed professional, government sector Employees?",

        answer:
          "Yes, this program supports all Mid-senior individuals irrespective of profession, industry, sector. Some of our alumni are renowned Doctors, Lawyers, Engineers, Government-body Heads, and Small-business owners as well who have been able to change their personas with our program. This program is beneficial for everyone.",
      },
    ],
  },

  individualProgramsFAQData: {
    faq: {
      Leadership_Essentials_Program: [
        {
          id: 1,
          question: ". Will this be 1:1 mentoring?",
          answer:
            "At Iron Lady, we follow a highly personalized mentoring approach as we like to focus on each individual. All your questions and challenges will be resolved in the group during the sessions especially during Q&A Sessions.",
        },
        {
          id: 2,
          question:
            ". Will I get additional support after the program is over?",
          answer:
            "We work with each of our community members very closely to ensure they are able to achieve the desired results. We will keep our alumni updated with upcoming programs, short-term courses that will benefit them. Also, wherever possible we try to resolve your queries in the best of our capacities.",
        },
        {
          id: 3,
          question: ". What are the potential payment options?",
          answer:
            "We suggest you make a one-time payment as this is an investment for your development. However, we do provide easy EMI options in partnership with our banking partners.",
        },
        {
          id: 4,
          question:
            ". Will the program be helpful in achieving my personal goals as well?",
          answer:
            "The program focuses on all kinds of goals that you may want to achieve for yourself in the future. Our session on B-HAGS will support you in envisioning a goal that is most suitable for your personal and professional development.",
        },
      ],
      "100_Board_Members_Program": [
        {
          id: 1,
          question: ". Will this be 1:1 mentoring?",

          answer:
            "All the sessions of 100 Board Members Program are group sessions. However, individual challenges will be handled through QnA sessions in private unrecorded sessions.",
        },
        {
          id: 2,
          question:
            ". Will I get additional support after the program is over?",

          answer:
            "There's a 100 Board Members community. The Program content access is provided for 6 months after the program is completed, 1 year from the date of registration.",
        },
        {
          id: 3,
          question: ". What are the potential payment options?",

          answer:
            "We do provide easy EMI options, through our banking partners.",
        },
        {
          id: 4,

          question:
            ". Is this program suitable for a self-employed professional, government sector Employees?",

          answer:
            "Yes, this program supports all Mid-senior individuals irrespective of profession, industry, sector. Some of our alumni are renowned Doctors, Lawyers, Engineers, Government-body Heads, and Small-business owners as well who have been able to change their personas with our program. This program is beneficial for everyone.",
        },
      ],
      Master_of_Business_Warfare: [
        {
          id: 1,
          question: ". Will this be 1:1 mentoring?",
          answer:
            "At Iron Lady, we follow a highly personalized mentoring approach as we like to focus on each individual. All your questions and challenges will be resolved in the group during the sessions especially during Q&A Sessions.",
        },
        {
          id: 2,
          question:
            ". Will I get additional support after the program is over?",
          answer:
            "We work with each of our community members very closely to ensure they are able to achieve the desired results. We will keep our alumni updated with upcoming programs, short-term courses that will benefit them. Also, wherever possible we try to resolve your queries in the best of our capacities.",
        },
        {
          id: 3,
          question: ". What are the potential payment options?",
          answer:
            "We suggest you make a one-time payment as this is an investment for your development. However, we do provide easy EMI options in partnership with our banking partners.",
        },
        {
          id: 4,
          question:
            ". Will the program be helpful in achieving my personal goals as well?",
          answer:
            "The program focuses on all kinds of goals that you may want to achieve for yourself in the future. Our session on B-HAGS will support you in envisioning a goal that is most suitable for your personal and professional development.",
        },
      ],
    },
  },

  individualProgramsFAQData_Hindi: {
    faq: {
      Leadership_Essentials_Program: [
        {
          id: 1,
          question: ". क्या यह 1:1 मार्गदर्शन होगा?",
          answer:
            "लीडरशिप एसेंशियल प्रोग्राम के सभी सत्र समूह सत्र हैं। हालाँकि, व्यक्तिगत चुनौतियाँ भी समूह सत्र हैं। इनका संचालन निजी गैर-रिकॉर्डेड सत्रों में प्रश्नोत्तर सत्रों के माध्यम से किया जाएगा।",
        },
        {
          id: 2,
          question:
            ". क्या कार्यक्रम समाप्त होने के बाद मुझे अतिरिक्त सहायता मिलेगी?",
          answer:
            "एक लीडरशिप एसेंशियल प्रोग्राम है। प्रोग्राम की विषयवस्तु, पंजीकरण की तारीख से 1 वर्ष के लिए प्रदान की जाती है।",
        },
        {
          id: 3,
          question: ". संभावित भुगतान विकल्प क्या हैं?",
          answer:
            "हम अपने बैंकिंग भागीदारों के माध्यम से आसान ईएमआई विकल्प प्रदान करते हैं।",
        },
        {
          id: 4,
          question:
            ". क्या यह कार्यक्रम मेरे व्यक्तिगत लक्ष्यों को प्राप्त करने में भी सहायक होगा?",
          answer:
            "यह कार्यक्रम उन सभी प्रकार के लक्ष्यों पर केंद्रित है जिन्हें आप भविष्य में अपने लिए प्राप्त करना चाहते हैं। BHAG पर हमारा सेशन आपको एक ऐसे लक्ष्य की कल्पना करने में सहायता करेगा जो आपके लिए सबसे उपयुक्त है आपके व्यक्तिगत और व्यावसायिक विकास के लिए।",
        },
        {
          id: 5,
          question:
            ". क्या यह कार्यक्रम स्व-नियोजित पेशेवर, सरकारी क्षेत्र के कर्मचारियों के लिए उपयुक्त है?",
          answer:
            "हां, यह कार्यक्रम सभी मध्यम-वरिष्ठ व्यक्तियों को सहायता प्रदान करता है, चाहे वे किसी भी पेशे, उद्योग, क्षेत्र से हों। हमारे कुछ पूर्व छात्र प्रसिद्ध डॉक्टर, वकील, इंजीनियर, सरकारी निकाय प्रमुख और छोटे व्यवसाय के मालिक भी हैं, जो हमारे कार्यक्रम के साथ अपने व्यक्तित्व को बदलने में सक्षम हुए हैं, यह कार्यक्रम सभी के लिए फायदेमंद है।",
        },
      ],
    },
  },

  footer: {
    c: "© ",
    copyRight: " IRON LADY. ALL RIGHTS RESERVED",
    unit: "A unit of Magic Wand Empowerment Private Limited",
    elevating: "ELEVATING A MILLION WOMEN TO THE TOP",
    download: "Download one of our apps",
    android: "Android",
    ios: "IOS",
    follow: "Follow Us",
  },

  testimonialsHome: [
    {
      id: 1,
      name: "Minal Bhagat",
      image: urls.minalBhagat,
      text: "I was surprised that even the online medium could be so effective and impactful. I am already being shameless and achieving many breakthroughs.",
    },
    {
      id: 2,
      name: "Vinath Hegde",
      image: urls.vinathHegde,
      text: "The Iron Program was very effective and I used the principles to win my UN Ambassador Crown. The program and it's purpose is very dear to my heart.",
    },
    {
      id: 3,
      name: "Pushpalatha M.S",
      image: urls.pushpa,
      text: "Iron Lady Program helped me and my co-founder Dr. Asha Vijay to scale our operations. All thanks to Rajesh and his team at Iron lady. Highly recommend iron Lady to all the women.",
    },
  ],

  testimonialsProgram: [
    {
      id: 1,
      name: "Minal Bhagat",
      image: urls.minalBhagat,
      text: "I was surprised that even the online medium could be so effective and impactful. I am already Being Shameless and achieving many breakthroughs.",
    },
    {
      id: 2,
      name: "Vinath Hegde",
      image: urls.vinathHegde,
      text: "The Iron Program was very effective and I used the principles to win my UN Ambassador Crown. The program and its purpose is very dear to my heart.",
    },
    {
      id: 3,
      name: "Pushpalath MS HARI",
      image: urls.pushpa,
      text: "Iron Lady Program helped me and my co-founder Dr. Asha Vijay to scale our operations. All thanks to Rajesh and his team at Iron lady. Highly Recommend iron Lady to all the women.",
    },
  ],

  testimonialsScholarship: [
    {
      id: 1,
      name: "Poornima Dixit",
      designation: "Director - Shipsy",
      image: urls.poornima,
      text: "I’ve been so fortunate to grow through Iron Lady. I’ve paid it forward and I’m delighted to see others benefitting from my contribution!",
    },
    {
      id: 2,
      name: "Anand Sudarshan",
      designation: "Ex-CEO, Manipal Group",
      image: urls.anand,
      text: "I’ve been fortunate to contribute to Iron Lady’s mission, right from the beginning. It’s a noble cause and I’ve been happy to contribute.",
    },
    {
      id: 3,
      name: "Harvinder Singh Mona",
      designation: "CFO, Rajasthan Royals IPL Team",
      image: urls.harvinder,
      text: "Having grown tremendously using Iron Lady Principles, it’s my privilege to contribute back and see others grow!",
    },
  ],

  googleReviews: [
    {
      id: 1,
      name: 'Nandita Roy Baul',
      image: urls.nandita,
      stars: 5,
      description: 'Iron Lady truly lives up to its name, empowering me to believe in myself, even at this stage in life. It’s given me the courage to break barriers, inspiring me to take on new challenges—like becoming a MUN coach at an age when most would think such dreams impossible. Despite lifes hurdles, Iron Lady has taught me that it’s never too late to start realizing your potential. Today, I stand proud, not just for daring to pursue my dream, but for knowing that with perseverance, anything is achievable. The unwavering support and dedication of the Iron Lady team are simply unmatched.'
    },
    {
      id: 2,
      name: 'Surya S',
      image: urls.surya,
      stars: 5,
      description: 'Iron lady = Transformation. Iron lady helped and helping thousands of women across India into leaders. Iron lady program is a perfect opportunity for redirecting our life into an amazing path both in personal and professional life. I\'m at the top of the sky to share the platform with wonderful community of women and continuously growing together is the best part even after the program. Learning extraordinary principles from live examples such as the leaders itself and seeing them achieve their goals is truly remarkable. Go for it ladies - become board members with Iron lady 💪🏻♥️'
    },
    {
      id: 3,
      name: 'Karuna Sapru',
      image: urls.karuna,
      stars: 5,
      description: 'It\'s been a wonderful experience joining the Iron Lady. I am using the techniques shared every day and I am seeing the difference in what I was as a professional a year ago and today. The clarity with which each topic is picked up and then coupled with hands-on exercises has helped immensely. I wish the Iron Lady team all the best in achieving their vision, and I am forever grateful for the direction that they showed me.'
    },
    {
      id: 4,
      name: 'Rabeka Akerele',
      image: urls.rabeka,
      stars: 4,
      description: 'I wish to express my heartfelt gratitude for the exceptional session on C-Suite pathway for business owners held by Suvarna, the expertise, insights, and enthusiasm shared were truly inspiring, and empowering. I gained valuable knowledge, practical tools, and a renewed sense of confidence to pursue my entrepreneurial aspirations. I appreciate the time, effort, and dedication that went into organizing and delivering this session. Thank you again for your support and guidance. I look forward to applying the lessons learnt and staying connected with the tribe of Iron Lady community.'
    },
    {
      id: 5,
      name: 'Chaya Devi Rudraraju',
      image: urls.chaya,
      stars: 5,
      description: 'The Iron Lady program gave me excellent support for my success. When I started my career again, I was clueless on how to start this new journey—I was in a boat without an oar. Iron Lady supported my journey, and within 6 months I reached my target and became the title winner of Mrs. India Andhra Pradesh. I followed all the principles of Iron Lady, and I went for Nationals where I became the Mrs. India second runner-up. Thank you so much for supporting me. Now I have started my own company and am moving forward with Iron Lady principles.'
    },
    {
      id: 6,
      name: 'Sreeveni V',
      image: urls.sreeveni,
      stars: 5,
      description: 'Iron Lady platform is an amazing and experiential platform. My personal experience is that it helps you first understand "What\'s Stopping you to reach your Goals," and then guides you to plan strategically the process of scaling up in whatever your goal is. It\'s a great guide for freshers, job seekers, working professionals, and entrepreneurs. It also helps homemakers to look into their own potential and make the best use of it for their future as well. Wishing the Team IRON Lady a huge success in empowering the women all around.'
    },
    {
      id: 7,
      name: 'Dr. Archanaa Dongre',
      image: urls.archana,
      stars: 5,
      description: 'Iron Lady platform brought a big change in my personality. It’s one platform where you get full support and personal attention to achieve goals in your life. It’s so amazing to have great leaders to take sessions on this platform. Rajesh sir and Suvarna madam are doing great work by helping women reach their highest potential. They make you challenge your own limits and see your new self. However, one needs to exactly follow what has been asked to get results. If done properly, all success is yours.'
    },
    {
      id: 8,
      name: 'Sunila Nambiar',
      image: urls.sunila,
      stars: 4,
      description: 'I think I made a good decision joining the Iron Lady Leadership Essentials program. From the program, I feel that I am in better control in terms of execution and managing time productively, using the frameworks designed by Iron Lady sessions. I can also see a shift in my energies and language patterns. Following the guidelines and structure of leveraging professional growth, I feel much more confident than I was before. Now, I believe, with all practices in place, in a few weeks I expect a major breakthrough and to achieve my BHAG. Thanks to the Iron Lady team for their consistent follow-up and support.'
    },
    {
      id: 9,
      name: 'Dr. Darshana Vithalani',
      image: urls.darshana,
      stars: 4,
      description: 'Leadership Essential Program is unique in its approach of weeding off unempowering beliefs, breaking past conditioning and barriers, and setting the stage to sow the seeds of results needed for professional and personal growth. It comes with a set of toolkits which are actually enablers to transform workplace responses through mindful tactics. I appreciate the guidance and time that the program leader and accomplished women leaders provide to the cohort.'
    },
    {
      id: 10,
      name: 'Veda Prasad',
      image: urls.veda,
      stars: 5,
      description: 'Taking up the Iron Lady Leadership Program during my career break was one of the best decisions I\'ve made. Over the course of a month, the program helped me hone my leadership skills, identify my strengths, and leverage them to become the best version of myself. It covers every aspect essential for building both personality and career. Their vision of empowering women to reach greater heights is truly inspiring. The time and money invested were absolutely worth it.'
    }
  ],  

  baseUrl: {
    navMenu:
      "https://script.google.com/macros/s/AKfycbzPUAKXHiukG8Dw8MyxXDy3v8rLC_9noBowsa1e33UF7qrx2xTTjtRvbZNi-WaSU75pSw/exec",
  },

  advertisments: ["google", "facebook", "linkedin", "website", "instagram"],

  forms: {
    getInTouch: "Get In Touch Form",
    refer: "Refer",
    referForm: "Refer Form",
    getInTouchTitle: "Let's Connect",
    preferenceLabel: "Would you like to know more on call or email?",
    ilRegistration: " Registration form",
    workProfile: "Work Profile",
  },

  Errors: {
    fullName: "Please enter your full name",
    email: "Please enter a valid email address",
    phone: "Please enter a valid phone number",
    preference: "Please select contact method",
    refer: "Plese enter refer by",
    experience: "Please enter your experience",
    workProfile: "Please select work profile",
  },

  REACT_APP_SECRET_KEY: "iliamironlayecosytemRocks#@102",

  imageExtensions: ["jpg", "jpeg", "png"],

  referralBenefits: [
    {
      id: 1,
      title: "On Attending Masterclass",
      imageURL: urls.referralImg1,
      referee:
        "<span style='font-weight: bold; font-size: 1.1rem; font-family: Fira-sans-light'>Your Friend: </span><span style='font-family: Fira-sans-light'>Free access to Iron Lady Masterclass worth <span style='font-weight: bold; color: #f52929'>Rs 499/-*</span></span>",
    },
    {
      id: 2,
      title: "On Signing Up For Leadership Essentials Program",
      imageURL: urls.referralImg2,
      referrer:
        "<span style='font-weight: bold; font-size: 1.1rem; font-family: Fira-sans-light'>You: </span><span style='font-family: Fira-sans-light'>Rs 2000 cash reward or <span style='font-weight: bold; color: #f52929'>Rs 2000</span> off on your course fee at Iron Lady.*</span>",
      referee:
        "<span style='font-weight: bold; font-size: 1.1rem; font-family: Fira-sans-light'>Your Friend: </span><span style='font-family: Fira-sans-light'><span style='font-weight: bold; color: #f52929'>30% Off </span>on their <span style='font-weight: bold;'>Leadership Essential Program</span> fee.*</span>",
    },
    {
      id: 3,
      title: "On Signing Up For 100 Board Members",
      imageURL: urls.referralImg3,
      referrer:
        "<span style='font-weight: bold; font-size: 1.1rem; font-family: Fira-sans-light'>You: </span><span style='font-family: Fira-sans-light'>Rs 5000 cash reward or <span style='font-weight: bold; color: #f52929'>Rs 5000</span> off on your course fee at Iron Lady.*</span>",
      referee:
        "<span style='font-weight: bold; font-size: 1.1rem; font-family: Fira-sans-light'>Your Friend: </span><span style='font-family: Fira-sans-light'><span style='font-weight: bold; color: #f52929'>25% Off</span> on their <span style='font-weight: bold;'>100 Board Members</span> course fee.*</span>",
    },
  ],

  referralSteps: [
    {
      id: 1,
      title: "Apply",
      description: "Submit referral details in the form below.",
    },
    {
      id: 2,
      title: "Verification",
      description:
        "Our team will review your application and confirm eligibility.",
    },
    {
      id: 3,
      title: "Get Benefits",
      description:
        "Upon approval, rewards will be granted as per the terms and conditions.",
    },
  ],

  referralProgram: {
    faq: [
      {
        id: 1,
        question: "How can I earn rewards?",
        answer:
          "Earn rewards by referring participants who complete their registration within 30 days.",
      },
      {
        id: 2,
        question: "Can I receive benefits for multiple courses?",
        answer:
          "No, benefits are only applicable to the first course referred.",
      },
      {
        id: 3,
        question: "When will referred participants receive their benefits?",
        answer:
          "Participants will receive their benefits by the 10th of the following month.",
      },
      {
        id: 4,
        question: "Can I refer more than one participant?",
        answer:
          "Yes, you can earn unlimited rewards by referring multiple participants.",
      },
      {
        id: 5,
        question: "What if the referred participant doesn't pay in full?",
        answer:
          "Benefits are only provided once the referred participant has made full payment for the course.",
      },
    ],
  },

  scholarshipProgram: {
    faq: [
      {
        id: 1,
        question: "What is the Iron Lady Scholarship Program?",
        answer:
          "The Iron Lady Scholarship Program is part of our commitment to empowering women who want to advance their careers but may lack the financial means to do so. Through various levels of scholarships, we support deserving women, helping them access the necessary leadership skills and training to break through barriers.",
      },
      {
        id: 2,
        question: "How does the Pay it Forward initiative work?",
        answer:
          "If you have benefited from the Iron Lady platform, you have the opportunity to 'pay it forward' by contributing to the scholarship program. Your contribution can help another woman who is eager to grow but may not have the financial resources.",
      },
      {
        "id": 3,
        "question": "How much can I contribute?",
        "answer": "You can contribute at different levels based on your preference:<br>● Part Scholarship for one person: ₹15,000<br>● Full Scholarship for one person: ₹33,000<br>● Multiple Scholarships: ₹33,000 x 5<br>Your contribution can truly change a life by providing someone with the skills and knowledge to excel in their career."
      },
      {
        "id": 4,
        "question": "Who are the recipients of the FULL scholarship?",
        "answer": "We provide full scholarships to women who meet the following criteria:<br>● Single mothers who are currently not working.<br>● Women who are committed to growth but lack the financial resources.<br>● Women who have been on a career break for an extended period and are looking to re-enter the workforce."
      },
      {
        id: 5,
        question: "How can my contribution make an impact?",
        answer:
          "By contributing to the Iron Lady Scholarship Program, you are helping women who are often overlooked in traditional career development opportunities. Your support enables them to gain the skills, confidence, and leadership abilities needed to navigate their professional journeys and make a lasting impact.",
      },
      {
        id: 6,
        question: "How do I contribute to the Pay it Forward initiative?",
        answer:
          "To make a contribution, simply visit our website or app, where you’ll find the option to donate towards a scholarship. Whether you choose to support a single person or multiple women, your donation is an investment in someone’s future.",
      },
      {
        id: 7,
        question: "Is my contribution tax-deductible?",
        answer:
          "Please check with your financial advisor or our team to confirm whether contributions to the Iron Lady Scholarship Program qualify for tax deductions based on your location and applicable laws.",
      },
      {
        id: 8,
        question: "Can I contribute more than the listed amounts?",
        answer:
          "Absolutely! If you would like to contribute more or offer additional support, we welcome any amount that aligns with your desire to make a positive impact.",
      },
      {
        id: 9,
        question: "What if I want to recommend someone for a scholarship?",
        answer:
          "If you know a deserving woman who could benefit from the Iron Lady Scholarship Program, please encourage her to apply through our website. We will review her application to determine if she qualifies for a full or partial scholarship.",
      },
    ],
  }

};
