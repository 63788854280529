import Styles from "./Policy.module.scss";
import AOS from "aos";
import { ThreeCircles } from "react-loader-spinner";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import ButtonWithLinks from "../../components/buttons/ButtonWithLink";
import { fetchIndividualPrograms } from "../../service/individualProgramsDataFetchService";

export default function Policy() {
  const { programName } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [filteredData, setFilteredData] = useState([]);
  const [updatedUrl, setUpdatedUrl] = useState("");

  const handleRemoveLastIndex = () => {
    const currentUrl = window.location.href;
    const lastIndex = currentUrl.lastIndexOf("/");
    const updatedUrl = currentUrl.substring(0, lastIndex);
    setUpdatedUrl(updatedUrl);
    window.location.href = updatedUrl; // Redirect to the updated URL
  };

  const fetchIndividualProgramData = async () => {
    try {
      setIsLoading(true);
      const response = await fetchIndividualPrograms();
      const individualProgramDataFromApi =
        response.data.data.individual_program;
      if (individualProgramDataFromApi) {
        setFilteredData(
          individualProgramDataFromApi.find(
            (item) => item.title.split(" ").join("_") === programName
          )
        );
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchIndividualProgramData();
  }, [programName]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <>
      {isLoading ? (
        <ThreeCircles
          height="70"
          width="70"
          color="#F52929"
          wrapperStyle={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "50vh",
          }}
          wrapperClass=""
          visible={true}
          ariaLabel="three-circles-rotating"
        />
      ) : (
        <div className={Styles["parent-div"]}>
          <div
            className={Styles["back-button"]}
            onClick={handleRemoveLastIndex}
          >
            <ButtonWithLinks
              id="back_lep_cancellation"
              name="Back"
              className={"button"}
              linkTo={updatedUrl}
            />
          </div>
          <span className={Styles["title"]}>
            {filteredData?.cancellationPolicy.title}
          </span>
          {filteredData?.cancellationPolicy &&
            filteredData?.cancellationPolicy.subtitle && (
              <span
                className={Styles["sub-title"]}
                dangerouslySetInnerHTML={{ __html: filteredData.cancellationPolicy.subtitle }}
              />
            )}

          {filteredData?.cancellationPolicy &&
            filteredData?.cancellationPolicy.policytitle && (
              <div className={Styles["policy-div"]}>
                <span className={Styles["policy-title"]}>
                  {filteredData.cancellationPolicy &&
                    filteredData?.cancellationPolicy.policytitle}
                </span>
                <div>
                  {filteredData?.cancellationPolicy.policies &&
                    filteredData?.cancellationPolicy.policies.map((item) => {
                      return (
                        <ul key={item.id}>
                          <li className={Styles["policy-items"]} dangerouslySetInnerHTML={{ __html: item.value }}>
                            
                          </li>
                        </ul>
                      );
                    })}
                </div>
              </div>
            )}

          <div className={Styles["gurantee-div"]}>
            <span className={Styles["gurantee-title"]}>
              {filteredData?.cancellationPolicy.guaranteetitle}
            </span>
            <br /><br />
            <span className={Styles["sub-title"]} dangerouslySetInnerHTML={{ __html: filteredData?.cancellationPolicy.guaranteesubtitle }}>
              
            </span>
            <br /><br />
            <div>
              {filteredData?.cancellationPolicy.guarantee &&
                filteredData?.cancellationPolicy.guarantee.map((item) => {
                  return (
                    <ul key={item.id}>
                      <li className={Styles["gurantee-items"]} dangerouslySetInnerHTML={{__html: item.value}}></li>
                    </ul>
                  );
                })}
            </div>
          </div>

          <span className={Styles["sub-title"]} dangerouslySetInnerHTML={{ __html: filteredData?.cancellationPolicy.guaranteeLastParagraph }}>
              
          </span>

          <div className={Styles["gurantee-div"]}>
            <span className={Styles["gurantee-title"]}>
              {filteredData?.cancellationPolicy.paymenttitle}
            </span>
            <br /><br />
            <div>
              {filteredData?.cancellationPolicy.payment &&
                filteredData?.cancellationPolicy.payment.map((item) => {
                  return (
                    <ul key={item.id}>
                      <li className={Styles["gurantee-items"]} dangerouslySetInnerHTML={{ __html: item.value }}></li>
                    </ul>
                  );
                })}
            </div>
          </div>

          <span className={Styles["sub-title"]} dangerouslySetInnerHTML={{ __html: filteredData?.cancellationPolicy.paymentLastParagraph }}>
              
          </span>

          <span className={Styles["sub-title"]} dangerouslySetInnerHTML={{ __html: filteredData?.cancellationPolicy.policyEndParagraph }}>
              
          </span>
          <br /><br />
        </div>
      )}
    </>
  );
}
