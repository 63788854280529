import React from 'react'
import Styles from "./Publication.module.scss";
const events = [
   {
     id: 1,
     image: "https://iron-lady.s3.ap-south-1.amazonaws.com/images/publication1/input/Copy+of+JU6A6552.JPG",
 name: "Walk to the Board: unique event fast-tracking women towards TOP Leadership",
 link: "https://businessmicro.in/business/walk-to-the-board-unique-event-fast-tracking-women-towards-top-leadership/",
   },
  {
    id: 2,
    image: "https://iron-lady.s3.ap-south-1.amazonaws.com/publication2/input/GOP_7198.JPG",
   name: "Global Leaders from Iron Lady come together to celebrate the Shakti within Indian women and announce 50 Under 50 Awards",
    link: "https://aninews.in/news/business/global-leaders-from-iron-lady-come-together-to-celebrate-the-shakti-within-indian-women-and-announce-50-under-50-awards20241122120704/",
  },
  
];


const Publication = () => {
  return (
    <div className={Styles["events-container"]}>
      <h2 className={Styles["events-title"]}>Publication</h2>
      <div className={Styles["events-list"]}>
        {events.map((event) => (
          <div key={event.id} className={Styles["event-card"]}>
          <div className={Styles["event-image"]}>
              <img src={event.image} alt={event.name} />
            </div>
            <div className={Styles["event-details"]}>
              <h3>{event.name}</h3>
              
              <p className={Styles["event-description"]}>{event.description}</p>
              <a href={event.link} className={Styles["event-button"]}>
                View Details
              </a>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Publication