import React from 'react';
import Styles from './RajeshBhat.module.scss';
import { FaLinkedin } from 'react-icons/fa'; 
import { urls } from '../../utils/urls';

const RajeshBhat = () => {
  return (
    <div className={Styles["rajesh-bhat-section"]}>

      <div className={Styles["header-section"]}>
        <h1 className={Styles["section-heading"]}>Rajesh Bhat</h1>
        <FaLinkedin onClick={()=> window.open("https://www.linkedin.com/in/meetrajeshbhat", "_blank")} className={Styles["linkedin-icon"]} /> 
      </div>

      <div className={Styles["designation-box"]}>
        <p>
          A villager turned serial social entrepreneur. Awarded Real Hero / TOP CEO / Featured with Amitabh Bachchan.
        </p>
      </div>

      <div className={Styles["content-section"]}>
        <div className={Styles["image-section"]}>
          <img
            src={urls.rajesh}
            alt="Rajesh Bhat"
            className={Styles["rajesh-image"]}
          />
        </div>

        <div className={Styles["text-section"]}>
          <p>
            Rajesh Bhat is a serial Social Entrepreneur and founder of initiatives like Head Held High, 1Bridge, and Iron Lady. He made headlines by <b> wearing a saree on a TEDx stage</b>, becoming the first man to ever do so.
          </p>
          <p>
            It wasn’t just an outfit choice—it was a statement. A stand against rigid societal norms. A call for inclusivity and equality.
          </p>
          <p>
            Rajesh started Head Held High in 2007, at the age of 25. He built a <b>revolutionary model</b> that transforms completely illiterate rural youth – to speak confidently in English and even pass 10th standard, in 6 months.
          </p>
          <p>
            Head Held High has been hailed on all national platforms as a one-of-its-kind platform and is now supporting lakhs of youth in 22 states across India!
          </p>
          <p>
            In 2017, Rajesh, along with a few other global leaders, started Iron Lady. Iron Lady has now become the No. 1 Leadership Platform for Women in India, supporting thousands of women to reach the TOP yearly.
          </p>
        </div>
      </div>

      <h2 className={Styles["sub-heading"]}>Early Life</h2>
      <div className={Styles["content-section"]}>
        <div className={Styles["text-section"]}>
          <p>
            Rajesh’s own story began in a tiny village in Karnataka. <b>He was the first in his
              community to become an engineer</b>—a feat that wasn’t easy but proved that big
            dreams can come from the smallest places. That journey shaped his life’s work to
            make sure others, no matter where they start, have the chance to achieve what once felt impossible.
          </p>
          <p>
            Today, Rajesh continues to inspire, lead, and challenge the world to think differently.
            He’s not just a man in a saree; he’s a man with a mission.
          </p>
        </div>
      </div>

      <h2 className={Styles["sub-heading"]}>Rajesh Bhat’s Professional Journey</h2>
      <div className={Styles["content-section"]}>
        <div className={Styles["text-section"]}>
          <p>
            Rajesh Bhat’s professional journey is a story of vision, grit, and relentless impact.
            Over the last 17 years, he has worn many hats—entrepreneur, changemaker,
            mentor—but at the heart of it all, he’s been a builder of possibilities.
          </p>

          <div className={Styles["role-section"]}>
            <div className={Styles["logo-container"]}>
              <img
                src={urls.headHeldHighLogo} // Replace with your logo URL
                alt="Head Held High Logo"
                className={Styles["role-logo"]}
              />
            </div>
            <div>
              <h3 className={Styles["sub-sub-heading"]}>Head Held High Foundation, Co-founder and Trustee</h3>
              <p>
                The transformative initiative established in 2007. Inspired by Rabindranath
                Tagore's iconic poem <b><i>Where the mind is without fear and the head is held
                  high,</i></b> This initiative has touched lives across <b>22 states in India</b>, with the
                support of top funders, corporates, and partners. Over 18 years, it has
                impacted lakhs of lives through empowerment, education, and sustainable
                development in rural communities.
              </p>
              <p>
                Rajesh continues to be on the board as a Trustee – supporting the CEO of
                Head Held High even today.
              </p>
            </div>
          </div>

          <div className={Styles["role-section"]}>
            <div className={Styles["logo-container"]}>
              <img
                src={urls.oneBridgeLogo} // Replace with your logo URL
                alt="1Bridge Logo"
                className={Styles["role-logo"]}
              />
            </div>
            <div>
              <h3 className={Styles["sub-sub-heading"]}>Co-founder and CEO of 1Bridge</h3>
              <p>
                Rajesh Bhat focused on creating an ecosystem for rural entrepreneurs,
                serving as a bridge between urban and rural markets. His efforts empowered
                local businesses, facilitated innovation, and drove economic growth in rural
                India, building connections that addressed critical gaps in resources and
                opportunities.
              </p>
            </div>
          </div>

          <div className={Styles["role-section"]}>
            <div className={Styles["logo-container"]}>
              <img
                src={urls.ironLadyLogo} // Replace with your logo URL
                alt="Iron Lady Logo"
                className={Styles["role-logo"]}
              />
            </div>
            <div>
              <h3 className={Styles["sub-sub-heading"]}>Iron Lady, Founder and Director</h3>
              <p>
                Rajesh founded Iron Lady with a singular goal: to help women step into their
                power as leaders. Under Rajesh’s leadership, Iron Lady has grown into India’s
                No. 1 platform for women interested in leadership, empowering over <b>100+
                  women to achieve the 1CR+ income milestone.</b>
              </p>
            </div>
          </div>

          <div className={Styles["role-section"]}>
            <div className={Styles["logo-container"]}>
              <img
                src={urls.winnerBenchLogo}
                alt="Winner Bench Logo"
                className={Styles["role-logo"]}
              />
            </div>
            <div>
              <h3 className={Styles["sub-sub-heading"]}>Winner Bench, Co-founder</h3>
              <p>
                Winner Bench is the leading Educational Transformation initiative for Children.
                It focuses on creating a nurturing environment where children can thrive
                academically and personally. The initiative emphasizes developing not only
                academic skills but also essential life skills, empowering children to face
                challenges with confidence.
              </p>
            </div>
          </div>
        </div>
      </div>

      <h2 className={Styles["sub-heading"]}>Making Dreams a Reality</h2>
      <div className={Styles["content-section"]}>
        <div className={Styles["text-section"]}>
          <p>
            Rajesh didn’t just dream of changing the world—he set out to make it happen. His
            initiatives, <b>Head Held High, Iron Lady, and Winner Bench</b>, are not just
            organizations; they are movements that have transformed the lives of countless
            people, proving that real change is possible with vision and action.
          </p>

          <div className={Styles["dream-section"]}>
            <h3 className={Styles["sub-sub-heading"]}>From Illiterate to 10th Pass in 6 Months</h3>
            <p>
              Rajesh co-founded <b>Head Held High</b> with a mission to uplift rural youth who were
              often overlooked and underserved. This groundbreaking initiative has empowered
              young individuals, transforming those who once struggled with basic literacy into
              confident, English-speaking professionals—all in just six months. Operating across
              <b> 22 states, Head Held High</b> has impacted <b>lakhs of lives</b>, providing not just skills but
              dignity, opportunity, and hope for a brighter future.
            </p>
            <p>
              What truly sets this journey apart are the personal stories of transformation. A young
              girl who once couldn’t imagine stepping outside her village is now leading a team in
              a bustling city. A boy, burdened by poverty, turned his family’s fortunes around after
              gaining the skills and confidence to succeed. <b>Head Held High</b> is a testament to the
              power of education and belief, unlocking the potential of individuals and entire
              communities.
            </p>
          </div>

          <div className={Styles["dream-section"]}>
            <h3 className={Styles["sub-sub-heading"]}>Empowering Women to Lead with Iron Lady</h3>
            <p>
              As a father of two daughters, Rajesh saw firsthand the questions and biases women
              face. <b>“Are women winning enough?”</b> became a question he couldn’t ignore. This
              led him to establish <b>Iron Lady</b>, a platform designed to empower women with the
              skills, strategies, and confidence to take on leadership roles.
            </p>
            <p>
              Under Rajesh’s guidance, <b>Iron Lady has grown into a thriving community of over
                76,000 women</b>, each finding strength, support, and inspiration to rise together. But
              these aren’t just numbers—they are stories of transformation. Stories of women
              reclaiming their voices, leading with authority in boardrooms, and rewriting their
              futures with purpose.
            </p>
            <p>
              More than <b>100 women have achieved the remarkable milestone of earning
                1CR+</b>, a dream once thought impossible by many. Through life-changing programs
              like <b>Leadership Essentials, 100 Board Members, and Master of Business
                Warfare</b>, these women didn’t just learn strategies—they discovered the courage to
              step up, break boundaries, and lead unapologetically.
            </p>
          </div>
        </div>
      </div>

      <h2 className={Styles["sub-heading"]}>A Legacy of Empowerment and Recognition</h2>
      <div className={Styles["content-section"]}>
        <div className={Styles["text-section"]}>
          <p>
            Rajesh Bhat’s journey is one of relentless determination to create meaningful
            change, earning him recognition and respect on both national and global platforms.
            His unwavering commitment to empowering underserved women has touched
            countless lives. By partnering with NGOs and educational institutions, Rajesh has
            run leadership workshops for underprivileged women, equipping them with the
            confidence and tools to shape their futures and uplift their communities. For this
            impactful work, <b>CNN recognized him as one of "The Real Heroes of India"</b>,
            celebrating his dedication to women’s empowerment.
          </p>

          <p>
            But Rajesh’s impact goes beyond individual success stories. His efforts have
            sparked a broader transformation, challenging long-held stereotypes and redefining
            how the world views women as leaders. Through bold and innovative leadership
            programs, he has created spaces where women can thrive, inspiring a larger
            conversation about gender equality in leadership.
          </p>

          <p>
            His visionary work has not gone unnoticed. <b>Major media outlets have featured his
              pioneering leadership initiatives, amplifying his call for a more inclusive and
              equitable world</b>. Rajesh’s accomplishments stand as a testament to his mission of
            empowering others, fostering change, and building a legacy that inspires generations
            to come.
          </p>
        </div>
      </div>

      <h2 className={Styles["sub-heading"]}>A Leader Who Transforms Lives</h2>
      <div className={Styles["content-section"]}>
        <div className={Styles["text-section"]}>
          <p>
            Rajesh Bhat’s journey is a testament to the power of purpose and action. From
            empowering rural youth with Head Held High to breaking barriers for women through
            Iron Lady, he has consistently turned vision into impact.
          </p>
          <p>
            Rajesh isn’t just creating programs—he’s building movements that inspire change
            and challenge norms. His legacy reminds us that true leadership lies in transforming
            lives and creating a future where everyone can rise.
          </p>
        </div>
      </div>


    </div>
  );
};

export default RajeshBhat;